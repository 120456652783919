import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import {
  DashboardProvider,
  IndustryProvider,
  SelectedIndustryProvider,
} from "./contextAPI/IndustryContext.js";
import { TabProvider } from "./contextAPI/TabContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <IndustryProvider>
      <TabProvider>
        <App />
      </TabProvider>
    </IndustryProvider>
  </Provider>
);

reportWebVitals();

import React from "react";
import Chart from "react-apexcharts";

const CustomApexBarChart = ({ colors, series, competences }) => {
  const categories = Array.isArray(competences)
    ? competences.map((c) => c?.name || "Unknown")
    : [];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      stackType: "100%",
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        borderRadius: 4,
        borderRadiusWhenStacked: "all",
        barHeight: "100%",
        horizontal: false,
      },
    },
    colors: colors,
    xaxis: {
      categories: categories.length ? categories : ["Unknown"],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        show: false,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          const competenceName = w?.config?.xaxis?.categories?.[dataPointIndex];
          return `${competenceName || "Unknown"}: ${val?.toFixed(2)}%`;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="chart-container">
      <Chart
        options={options}
        series={Array.isArray(series) ? series : []}
        type="bar"
        height={130}
        width={120}
      />
    </div>
  );
};

export default CustomApexBarChart;

import { useLocation } from "react-router-dom";
import TradeDownIcon from "../../../assets/icon/TradeDownIcon.svg";
import TradeUpIcon from "../../../assets/icon/TradeUpIcon.svg";
import CountUp from "../../../components/CountUp";

const StateCard = ({ icon, value, label, trend }) => {
  const location = useLocation();
  return (
    <div className="bg-[#F2F6FA] p-5 rounded-[15px] shadow-sm flex gap-5 items-center">
      <div
        className={`w-[50px] h-[50px] flex items-center justify-center rounded-full bg-[#083D4E]`}
      >
        <img src={icon} alt={label} className="w-6 h-6" />
      </div>
      <div className="w-[calc(100%-70px)]">
        <div className="text-[#333333] mb-[10px] font-medium text-base">
          {label}
        </div>
        <div className="text-[#333333] lg:text-xl text-lg font-bold flex items-center gap-[10px]">
          <CountUp targetNumber={value} />
          {(location.pathname.startsWith("/competences") ||
            location.pathname.startsWith("/UserDetailPage/")) &&
            trend && (
              <>
                {trend < 0 ? (
                  <img
                    src={TradeDownIcon}
                    alt="downward trend"
                    className="w-6 h-6"
                  />
                ) : (
                  <img
                    src={TradeUpIcon}
                    alt="upward trend"
                    className="w-6 h-6"
                  />
                )}
                <span className={trend > 0 ? "text-green-500" : "text-red-500"}>
                  {Math.abs(trend)}%
                </span>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default StateCard;

import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { geoCentroid } from "d3-geo";
import { Plus, Minus, Hand } from "lucide-react";
import { scaleOrdinal } from "d3-scale";
import Loader from "../../../components/Loader";

const TooltipSkeleton = () => (
  <div className="flex flex-col gap-2 animate-pulse">
    <div className="h-4 w-24 bg-gray-200 rounded"></div>
    <div className="h-3 w-20 bg-gray-100 rounded"></div>
  </div>
);

const Tooltip = ({ content, isLoading, position }) => (
  <div
    className="absolute z-50 bg-white px-4 py-2 rounded-lg shadow-lg border border-gray-200 text-center pointer-events-none"
    style={{
      left: position.x,
      top: position.y - 80,
      transform: "translateX(-50%)",
    }}
  >
    {isLoading ? (
      <TooltipSkeleton />
    ) : (
      <>
        <div className="font-medium">{content}</div>
        <div className="text-sm text-gray-500">Click to learn more</div>
      </>
    )}
  </div>
);

const worldGeoUrl =
  "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_110m_admin_0_countries.geojson";
const statesUrl =
  "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_10m_admin_1_states_provinces.geojson";
const austriaDistrictsUrl =
  "https://raw.githubusercontent.com/ginseng666/GeoJSON-TopoJSON-Austria/master/2021/simplified-99.9/bezirke_999_geo.json";
const germanyDistrictsUrl =
  "https://raw.githubusercontent.com/isellsoap/deutschlandGeoJSON/main/2_bundeslaender/4_niedrig.geo.json";

const colorScale = scaleOrdinal()
  .domain([
    "Hohes Fähigkeitsniveau",
    "Dazwischenliegend Fähigkeitsniveau",
    "Mittleres Fähigkeitsniveau",
    "Schlechtes Fähigkeitsniveau",
  ])
  .range(["#1a2f38", "#3ca0d0", "#d15b46", "#f9a65a"]);

const WorldMap = () => {
  const [worldData, setWorldData] = useState(null);
  const [germanStates, setGermanStates] = useState(null);
  const [austrianStates, setAustrianStates] = useState(null);
  const [austriaDistricts, setAustriaDistricts] = useState(null);
  const [germanyDistricts, setGermanyDistricts] = useState(null);
  const [position, setPosition] = useState({
    coordinates: [10, 51], // Centered on Central Europe
    zoom: 4,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const [isTooltipLoading, setIsTooltipLoading] = useState(false);
  const mapRef = useRef(null);

  const countryColors = useMemo(() => {
    if (!worldData) return {};
    const colors = {};
    worldData.features.forEach((feature, index) => {
      colors[feature.properties.ADM0_A3] = colorScale(
        [
          "Hohes Fähigkeitsniveau",
          "Dazwischenliegend Fähigkeitsniveau",
          "Mittleres Fähigkeitsniveau",
          "Schlechtes Fähigkeitsniveau",
        ][index % 4]
      );
    });
    return colors;
  }, [worldData]);

  const getStrokeWidth = (zoom) => {
    if (zoom < 2) return 0.2;
    if (zoom < 5) return 0.4;
    if (zoom < 10) return 0.6;
    if (zoom < 20) return 0.8;
    return 1;
  };

  const handleMouseEnter = async (event, name, type, country) => {
    const mapRect = mapRef.current.getBoundingClientRect();
    const x = event.clientX - mapRect.left;
    const y = event.clientY - mapRect.top;
    setTooltipPosition({ x, y });
    setShowTooltip(true);
    setIsTooltipLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setTooltipContent(`${name} ${type} (${country})`);
    setIsTooltipLoading(false);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setIsTooltipLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [worldResponse, statesResponse, austriaDistResponse, germanyDistResponse] =
          await Promise.all([
            fetch(worldGeoUrl),
            fetch(statesUrl),
            fetch(austriaDistrictsUrl),
            fetch(germanyDistrictsUrl),
          ]);

        const worldJson = await worldResponse.json();
        const statesJson = await statesResponse.json();
        const austriaDistrictsJson = await austriaDistResponse.json();
        const germanyDistrictsJson = await germanyDistResponse.json();

        const austrianStates = statesJson.features.filter(
          (state) => state.properties.admin === "Austria"
        );
        
        const germanStates = statesJson.features.filter(
          (state) => state.properties.admin === "Germany"
        );

        setWorldData(worldJson);
        setAustrianStates({ ...statesJson, features: austrianStates });
        setGermanStates({ ...statesJson, features: germanStates });
        setAustriaDistricts(austriaDistrictsJson);
        setGermanyDistricts(germanyDistrictsJson);
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };

    fetchData();
  }, []);

  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 0.8) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.2 }));
  };

  const handleMoveStart = () => {
    setIsDragging(true);
  };

  const handleMoveEnd = () => {
    setIsDragging(false);
  };

  if (!worldData || !austrianStates || !austriaDistricts || !germanyDistricts) {
    return <Loader />;
  }

  return (
    <div className="relative" ref={mapRef}>
      {showTooltip && (
        <Tooltip
          content={tooltipContent}
          isLoading={isTooltipLoading}
          position={tooltipPosition}
        />
      )}
      <div className="absolute top-4 right-4 z-10 flex flex-col gap-2">
        <button
          onClick={handleZoomIn}
          className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
        >
          <Plus className="w-6 h-6" />
        </button>
        <button
          onClick={handleZoomOut}
          className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
        >
          <Minus className="w-6 h-6" />
        </button>
      </div>
      <div className="absolute top-4 left-4 z-10">
        <Hand
          className={`w-6 h-6 ${
            isDragging ? "text-blue-500" : "text-gray-500"
          }`}
        />
      </div>
      <ComposableMap projection="geoMercator">
        <ZoomableGroup
          center={position.coordinates}
          zoom={position.zoom}
          onMoveStart={handleMoveStart}
          onMoveEnd={handleMoveEnd}
          maxZoom={50}
          minZoom={1}
        >
          {/* Render World Map */}
          <Geographies geography={worldData}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={countryColors[geo.properties.ADM0_A3]}
                  stroke="#FFFFFF"
                  strokeWidth={getStrokeWidth(position.zoom)}
                  style={{
                    default: { outline: "none" },
                    hover: {
                      fill: countryColors[geo.properties.ADM0_A3],
                      opacity: 0.8,
                      outline: "none",
                    },
                    pressed: { outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>

          {/* Render Austrian States */}
          <Geographies geography={austrianStates}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: "#3ca0d0",
                      outline: "#000",
                      fillOpacity: 0.8,
                      strokeWidth: getStrokeWidth(position.zoom),
                    },
                    hover: {
                      fill: "#2980b9",
                      outline: "#000",
                      fillOpacity: 0.8,
                      strokeWidth: getStrokeWidth(position.zoom),
                    },
                    pressed: {
                      fill: "#2980b9",
                      outline: "#000",
                      fillOpacity: 1,
                      strokeWidth: getStrokeWidth(position.zoom),
                    },
                  }}
                />
              ))
            }
          </Geographies>

          {/* Render German States */}
          <Geographies geography={germanStates}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: "#d15b46",
                      outline: "#000",
                      fillOpacity: 0.8,
                      strokeWidth: getStrokeWidth(position.zoom),
                    },
                    hover: {
                      fill: "#c0392b",
                      outline: "#000",
                      fillOpacity: 0.8,
                      strokeWidth: getStrokeWidth(position.zoom),
                    },
                    pressed: {
                      fill: "#c0392b",
                      outline: "#000",
                      fillOpacity: 1,
                      strokeWidth: getStrokeWidth(position.zoom),
                    },
                  }}
                />
              ))
            }
          </Geographies>

          {/* Render Austrian Districts */}
          <Geographies geography={austriaDistricts}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(e) =>
                    handleMouseEnter(e, geo.properties.name, "District", "Austria")
                  }
                  onMouseLeave={handleMouseLeave}
                  style={{
                    default: {
                      fill: "none",
                      stroke: "#000000",
                      strokeWidth: 0.1,
                      opacity: 0.8,
                    },
                    hover: {
                      fill: "#FFA500",
                      stroke: "#ffffff",
                      strokeWidth: 0.1,
                      opacity: 0.3,
                    },
                    pressed: {
                      fill: "#FF8C00",
                      stroke: "#ffffff",
                      strokeWidth: 0.1,
                      opacity: 0.5,
                    },
                  }}
                />
              ))
            }
          </Geographies>

          {/* Render German Districts */}
          <Geographies geography={germanyDistricts}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(e) =>
                    handleMouseEnter(e, geo.properties.name, "District", "Germany")
                  }
                  onMouseLeave={handleMouseLeave}
                  style={{
                    default: {
                      fill: "none",
                      stroke: "#000000",
                      strokeWidth: 0.1,
                      opacity: 0.8,
                    },
                    hover: {
                      fill: "#FF4500",
                      stroke: "#ffffff",
                      strokeWidth: 0.1,
                      opacity: 0.3,
                    },
                    pressed: {
                      fill: "#FF0000",
                      stroke: "#ffffff",
                      strokeWidth: 0.1,
                      opacity: 0.5,
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default WorldMap;
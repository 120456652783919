import React, { useEffect, useState } from "react";
import MultiSelect from "../../../components/MultiSelect";
import DownArrow from "../../../assets/icon/DownArrow.svg";
import CustomApexBarChart from "../../../components/Charts/CustomApexBarChart";
import { useDispatch, useSelector } from "react-redux";
import { fetchSkills } from "../../../redux/slice/skillSlice";
import { fetchScalings } from "../../../redux/slice/scalingSlice";
import { fetchJobsUserProfile } from "../../../redux/slice/jobsUsersSlice";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";

const EmployeeCompetenciesDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { skills, loading } = useSelector((state) => state.skills);
  const { scalings } = useSelector((state) => state.scalings);
  const { jobsUserProfile } = useSelector((state) => state.jobsUser);
  const [knowledge, setKnowledge] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    dispatch(fetchSkills());
    dispatch(fetchScalings());
    dispatch(fetchJobsUserProfile());
  }, []);

  useEffect(() => {
    if (selectedSkills.length === 0) {
      setKnowledge("");
    }
    setCurrentPage(1);
  }, [selectedSkills, knowledge]);

  const getKnowledgeLevelValue = (percentage) => {
    if (percentage >= 80) return "N5 - Experte";
    if (percentage >= 50) return "N4 - Umfassend";
    if (percentage >= 40) return "N3 - Sicher";
    if (percentage >= 30) return "N2 - Anwender";
    if (percentage >= 20) return "N1 - Grundkenntnisse";
    return "N0 - Keine Kenntnisse";
  };

  const employees = jobsUserProfile.map((user) => ({
    userId: user.userId,
    name: user.username || "Unknown",
    email: user.email,
    userImage: user.userImage,
    competencies: user.skills.map((skill) => skill.percentage),
    skills: user.skills.map((skill) => ({
      name: skill.skillName,
      percentage: skill.percentage,
      level: getKnowledgeLevelValue(skill.percentage),
    })),
  }));

  const handleUserClick = (employee) => {
    console.log(employee,"employee")
    navigate(`/UserDetailPage/${employee.userId}`, {
      state: {
        userId: employee.userId,
        skills: employee.skills,
        userName: employee.name,
        email: employee.email,
        userImage: employee.userImage,
      },
    });
  };

  const skillOptions = skills.map((skill) => ({
    value: skill.id,
    label: skill.name,
  }));

  const hasRequiredSkills = (employeeSkills, requiredSkills) => {
    return requiredSkills.every((selectedSkill) => {
      const skillName = skills.find((s) => s.id === selectedSkill.value)?.name;
      return employeeSkills.some((skill) => skill.name === skillName);
    });
  };

  const hasSkillsWithKnowledgeLevel = (
    employeeSkills,
    requiredSkills,
    requiredLevel
  ) => {
    return requiredSkills.some((selectedSkill) => {
      const skillName = skills.find((s) => s.id === selectedSkill.value)?.name;
      return employeeSkills.some(
        (skill) => skill.name === skillName && skill.level === requiredLevel
      );
    });
  };

  const filteredEmployees = employees.filter((employee) => {
    if (!selectedSkills.length && !knowledge) {
      return true;
    }

    if (selectedSkills.length && !knowledge) {
      return hasRequiredSkills(employee.skills, selectedSkills);
    }

    if (!selectedSkills.length && knowledge) {
      return employee.skills.some((skill) => skill.level === knowledge);
    }
    return (
      hasRequiredSkills(employee.skills, selectedSkills) &&
      hasSkillsWithKnowledgeLevel(employee.skills, selectedSkills, knowledge)
    );
  });

  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);
  const paginatedEmployees = filteredEmployees.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="mb-5 flex md:flex-row flex-col gap-5">
        <div className="lg:w-2/3 md:w-1/2 w-full">
          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
            Fähigkeiten
          </label>
          <MultiSelect
            options={skillOptions}
            selectedOptions={selectedSkills}
            onChange={setSelectedSkills}
            isLoading={loading}
          />
        </div>
        <div className="lg:w-1/3 md:w-1/2 w-full">
          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
            Kompetenzniveau (N1-N5)
          </label>
          <div className="relative">
            <select
              className={`border border-[#CCCCCC] rounded-lg px-4 h-9 w-full appearance-none ${
                !selectedSkills.length ? "bg-gray-100 cursor-not-allowed" : ""
              }`}
              value={knowledge}
              onChange={(e) => setKnowledge(e.target.value)}
              disabled={!selectedSkills.length}
            >
              <option value="" disabled>
                Arbeitsplatz wählen
              </option>
              {scalings.map((scaling) => (
                <option key={scaling.id} value={scaling.name}>
                  {scaling.name}
                </option>
              ))}
            </select>
            <img
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
              src={DownArrow}
              alt="Arrow"
              size={20}
            />
          </div>
        </div>
      </div>
      <div className="overflow-x-auto bg-white rounded-[15px]">
        {filteredEmployees.length > 0 ? (
          <table className="table-fixed w-full border-collapse">
            <thead>
              <tr className="text-left border-b border-gray-200">
                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                  Name
                </th>
                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                  Kompetenzen
                </th>
                {/* <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[190px]">
                Arbeitsplatz
              </th>
              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                Personalkosten
              </th> */}
                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[180px]">
                  Aktionen
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {console.log(paginatedEmployees,"paginatedEmployees")}
              {paginatedEmployees.map((employee, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="text-[#333333] font-normal text-sm px-5 py-[15px]" onClick={() => handleUserClick(employee)}>
                    {employee.name}
                  </td>
                  <td className="px-5 py-[15px] align-top" onClick={() => handleUserClick(employee)}>
                    {employee?.competencies?.length > 0 ? (
                      <CustomApexBarChart
                        colors={["#F0884D", "#F2F6FA"]}
                        series={[
                          {
                            name: "Filled",
                            data: employee.competencies || [],
                          },
                          {
                            name: "Remaining",
                            data: employee.competencies
                              ? employee.competencies.map((comp) => 100 - comp)
                              : [],
                          },
                        ]}
                        competences={employee.skills.map((skill) => ({
                          name: skill.name,
                        }))}
                      />
                    ) : (
                      <span className="text-gray-500 text-sm italic">
                        No competences added
                      </span>
                    )}
                  </td>
                  {/* <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                  {employee.position}
                </td>
                <td className="px-6 py-4">
                  <span className="bg-[#009951] rounded-[5px] text-[#333333] font-normal text-sm px-[5px] py-[4px] leading-[18px]">
                    {employee.cost}
                  </span>
                </td> */}
                  <td className="px-6 py-4">
                    <div className="relative">
                      <select className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none">
                        <option>Aktionen</option>
                      </select>
                      <img
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                        src={DownArrow}
                        alt="Arrow"
                        size={20}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center py-10 text-gray-500">
            <p className="text-lg italic">No Users Found</p>
          </div>
        )}
        {filteredEmployees.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </>
  );
};

export default EmployeeCompetenciesDashboard;

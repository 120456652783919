import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancies } from "../../../redux/slice/vacanciesSlice";
import StateCard from "../StateCard";
import Header from "../../../layout/Header";
import AddIcon from "../../../assets/icon/Add.svg";
import UsersIcon from "../../../assets/icon/UsersIcon.svg";
import GraphUpIcon from "../../../assets/icon/GraphUpIcon.svg";
import RedundancyIcon from "../../../assets/icon/BagIcon.svg";
import InnovativePower from "../../../assets/icon/InnovativePower.svg";
import CompetencyScore from "../../../assets/icon/CompetencyScoreIcon.svg";
import MicroChart from "../../../components/Charts/MicroChart";
import { useHumaticsCalculations } from "../../../hook/useHumaticsCalculations";

const CompetencesGraphDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { vacancies } = useSelector((state) => state.vacancies);
  const [currentVacancy, setCurrentVacancy] = useState(null);
  const [chartData, setChartData] = useState([]);
  const isNonEditableRoute = location.pathname === `/dashboard/${id}`;

  const { calculateMetrics } = useHumaticsCalculations();

  useEffect(() => {
    dispatch(fetchVacancies());
  }, [dispatch]);

  const averageMetricsVacancies = vacancies.reduce(
    (acc, position) => {
      const metrics = calculateMetrics(
        position.competences.map((c) => c.value)
      );
      return {
        h: metrics.h,
        t: metrics.t,
        r: metrics.r,
        i: metrics.i,
        c: metrics.c,
        competencies: metrics.competencies,
      };
    },
    { h: 0, t: 0, r: 0, i: 0, c: 0 }
  );

  useEffect(() => {
    if (vacancies && id) {
      const foundVacancy = vacancies?.find((vac) => vac._id === id);
      setCurrentVacancy(foundVacancy);

      if (foundVacancy && foundVacancy.competences) {
        const newChartData = foundVacancy.competences.map((comp) => ({
          skill: comp.skill.name,
          percentage: comp.value,
        }));
        setChartData(newChartData);
      }
    }
  }, [vacancies, id]);

  const handleSkillUpdate = () => {};
  return (
    <div>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                {currentVacancy ? currentVacancy?.job?.name : "Loading..."}
              </h2>
              <button className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                <img src={AddIcon} alt="Add" />
                Neue Kompetenz hinzufügen
              </button>
            </div>

            <div className="bg-white p-5 rounded-[20px]">
              <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                <StateCard
                  icon={UsersIcon}
                  value={averageMetricsVacancies.h.toFixed(2)}
                  label="Humanpotential"
                  trend={-2}
                />
                <StateCard
                  icon={GraphUpIcon}
                  value={averageMetricsVacancies.t.toFixed(2)}
                  label="Ökonomische Temperatur"
                />
                <StateCard
                  icon={RedundancyIcon}
                  value={averageMetricsVacancies.r.toFixed(2)}
                  label="Redundanz"
                  trend={4}
                />
                <StateCard
                  icon={InnovativePower}
                  value={averageMetricsVacancies.i.toFixed(2)}
                  label="Innovationskraft"
                  trend={-3}
                />
                <StateCard
                  icon={CompetencyScore}
                  value={averageMetricsVacancies.c.toFixed(2)}
                  label="Kompetenzwert"
                />
              </div>
              <div className="flex flex-col md:flex-row items-start justify-between max-w-6xl mx-auto p-6">
                <div className="chart-container w-full md:w-3/4 mb-6 md:mb-0">
                  <MicroChart
                    graphData={chartData}
                    onSkillUpdate={handleSkillUpdate}
                    isEditable={!isNonEditableRoute}
                  />
                </div>
                <div className="legend w-full md:w-1/4 md:ml-6 text-sm text-gray-500 mt-20">
                  <p className="mb-2">80 % NI - Experte</p>
                  <p className="mb-2">50 % NI - Umfassend</p>
                  <p className="mb-2">40 % NI - Sicher</p>
                  <p className="mb-2">30 % NI - Anwender</p>
                  <p className="mb-2">20 % NI - Grundkenntnisse</p>
                  <p className="mb-2">10 % NI - Keine Kenntnisse</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetencesGraphDetail;

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./route";
import { Toaster } from "react-hot-toast";
import { logoutUser } from "./redux/slice/authSlice";
import { useDispatch } from "react-redux";

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const tokenExpiry = JSON.parse(atob(token.split(".")[1])).exp * 1000; 
      const now = Date.now();

      if (now >= tokenExpiry) {
        dispatch(logoutUser());
      } else {
        const timeout = setTimeout(() => {
          dispatch(logoutUser());
        }, tokenExpiry - now);

        return () => clearTimeout(timeout); 
      }
    }
  }, [dispatch]);

  return (
    <>
      <Router>
        <Toaster position="top-center" />
        <AppRoutes />
      </Router>
    </>
  );
}

export default App;

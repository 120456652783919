// IndustryContext.js
import React, { createContext, useState, useContext } from 'react';

const IndustryContext = createContext();

export const IndustryProvider = ({ children }) => {
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [filteredVacancies, setFilteredVacancies] = useState([]);

  const updateIndustry = (industryId, industryJobs) => {
    setSelectedIndustry(industryId);
    setSelectedJobs(industryJobs || []);
  };

  const updateJob = (jobName, vacancies) => {
    setSelectedJob(jobName);
    const filtered = vacancies.filter(
      (vacancy) => vacancy.job.name === jobName
    );
    setFilteredVacancies(filtered);
  };

  const clearSelections = () => {
    setSelectedIndustry('');
    setSelectedJobs([]);
    setSelectedJob('');
    setFilteredVacancies([]);
  };

  return (
    <IndustryContext.Provider 
      value={{ 
        selectedIndustry, 
        selectedJobs,
        selectedJob,
        filteredVacancies,
        updateIndustry,
        updateJob,
        clearSelections
      }}
    >
      {children}
    </IndustryContext.Provider>
  );
};

export const useIndustry = () => {
  const context = useContext(IndustryContext);
  if (!context) {
    throw new Error('useIndustry must be used within an IndustryProvider');
  }
  return context;
};
import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "../redux/slice/profileSlice";
import authReducer from "../redux/slice/authSlice";
import useReducer from "../redux/slice/userSlice";
import skillsReducer from "../redux/slice/skillSlice";
import vacanciesSlice from "./slice/vacanciesSlice";
import jobsUsersSlice from "./slice/jobsUsersSlice";
import industryReducer from "./slice/industriesSlice";
import scalingReducer from "./slice/scalingSlice";

const store = configureStore({
  reducer: {
    profile: profileReducer,
    user: useReducer,
    auth: authReducer,
    skills: skillsReducer,
    vacancies: vacanciesSlice,
    jobsUser: jobsUsersSlice,
    industries: industryReducer,
    scalings: scalingReducer,
  },
});

export default store;

import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import UsersIcon from "../../../assets/icon/UsersIcon.svg";
import GraphUpIcon from "../../../assets/icon/GraphUpIcon.svg";
import InnovativePower from "../../../assets/icon/InnovativePower.svg";
import RedundancyIcon from "../../../assets/icon/BagIcon.svg";
import CompetencyScore from "../../../assets/icon/CompetencyScoreIcon.svg";
import StateCard from "../../Dashboard/StateCard";
import { fetchVacancies } from "../../../redux/slice/vacanciesSlice";
import {
  fetchSelectedSkills,
  fetchSkills,
} from "../../../redux/slice/skillSlice";
import { useLocation, useParams } from "react-router-dom";
import { useHumaticsCalculations } from "../../../hook/useHumaticsCalculations";
import { fetchIndustries } from "../../../redux/slice/industriesSlice";
import { fetchJobsUserProfile } from "../../../redux/slice/jobsUsersSlice";
import { fetchUserData } from "../../../redux/slice/profileSlice";
import { InfoIcon } from "lucide-react";
import CountUp from "../../../components/CountUp";
import CircularProgressChart from "../../../components/Charts/CircularProgressChart";
import ColorBar from "../../../components/Charts/ColorBarGraph";
import MicroChart from "../../../components/Charts/MicroChart";
import EditIcon from "../../../assets/icon/EditIcon.svg";
import deleteIcon from "../../../assets/icon/deleteIcon.svg";
import ArrowRight from "../../../assets/icon/Arrow_Right.svg";
import { useTab } from "../../../contextAPI/TabContext";

const UserDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { vacancyId } = useParams();
  const [selectedVacany, setSelectedVacancy] = useState(null);
  const { activeTab, updateActiveTab } = useTab();
  // const [activeTab] = useState(() => {
  //   return sessionStorage.getItem("dashboardActiveTab") || "matching";
  // });
  const { vacancies, loading } = useSelector((state) => state.vacancies);
  const { calculateMetrics } = useHumaticsCalculations();
  const [localSelectedSkills, setLocalSelectedSkills] = useState([]);
  // const { userId, skills, userName } = location.state || {};
  const { userId, skills: navigationSkills, userName, email, userImage } = location.state || {};
  const [userSkills, setUserSkills] = useState([]);
  const isNonEditableRoute = location.pathname === `/UserDetailPage/${id}`;
  const selectedSkills = useSelector(
    (state) => state?.skills?.selectedSkill?.skills
  );
  const pageTitle = userName
    ? `Kompetenz des Benutzers- ${userName}`
    : "Kompetenz des Benutzers- Unknown User";

    useEffect(() => {
      if (navigationSkills && Array.isArray(navigationSkills)) {
        setUserSkills(navigationSkills);
      } else {
        dispatch(fetchJobsUserProfile()).then((response) => {
          if (Array.isArray(response.payload)) {
            const user = response.payload.find((user) => user.userId === parseInt(userId));
            if (user && user.skills) {
              console.log("Setting skills from API:", user.skills);
              setUserSkills(user.skills);
            }
          }
        });
      }
    }, [navigationSkills, userId, dispatch]);

  // useEffect(() => {
  //   if (skills) {
  //     setUserSkills(skills);
  //   } else {
  //     dispatch(fetchJobsUserProfile()).then((response) => {
  //       const user = response.find((user) => user.userId === userId);
  //       setUserSkills(user ? user.skills : []);
  //     });
  //   }
  // }, [skills, userId, dispatch]);

  const microChartData = userSkills.map((skill) => ({
    skill: skill.name || skill.skillName,
    percentage: skill.percentage,
  }));

  const averageMetricsVacancies = vacancies?.reduce(
    (acc, position) => {
      const metrics = calculateMetrics(
        position.competences.map((c) => c.value)
      );
      return {
        h: metrics.h,
        t: metrics.t,
        r: metrics.r,
        i: metrics.i,
        c: metrics.c,
        competencies: metrics.competencies,
        mu: metrics.mu,
      };
    },
    { h: 0, t: 0, r: 0, i: 0, c: 0, mu: 0 }
  );
  // useEffect(() => {
  //   sessionStorage.setItem("dashboardActiveTab", activeTab);
  // }, [activeTab]);

  useEffect(() => {
    dispatch(fetchUserData());
    dispatch(fetchVacancies());
    dispatch(fetchJobsUserProfile());
    dispatch(fetchSelectedSkills());
    dispatch(fetchSkills());
    dispatch(fetchIndustries());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSkills) {
      setLocalSelectedSkills(selectedSkills);
    }
  }, [selectedSkills, setLocalSelectedSkills]);

  useEffect(() => {
    if (location.state?.vacancy) {
      setSelectedVacancy(location.state.vacancy);
    } else {
      const foundVacancy = vacancies.find((v) => v._id === vacancyId);
      setSelectedVacancy(foundVacancy);
    }
  }, [location.state, vacancies, vacancyId, setSelectedVacancy]);

  return (
    <>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                {pageTitle}
              </h2>
              <div className="flex space-x-2">
                <button className="bg-[#FFFFFF] text-black text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                  <img src={EditIcon} alt="Globe" />
                  Bearbeiten
                </button>
                <button className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                  <img src={deleteIcon} alt="Globe" />
                  Löschen
                </button>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="bg-white p-5 rounded-[20px]">
                <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                  <StateCard
                    icon={UsersIcon}
                    value={averageMetricsVacancies.h.toFixed(2)}
                    label="Humanpotential"
                    trend={-2}
                  />
                  <StateCard
                    icon={GraphUpIcon}
                    // value="0"
                    label="Ökonomische Temperatur"
                    value={averageMetricsVacancies.t.toFixed(2)}
                  />
                  <StateCard
                    icon={RedundancyIcon}
                    // value="2.42"
                    value={averageMetricsVacancies.r.toFixed(2)}
                    label="Redundanz"
                    trend={4}
                  />
                  <StateCard
                    icon={InnovativePower}
                    // value="1.24"
                    value={averageMetricsVacancies.i.toFixed(2)}
                    label="Innovationskraft"
                    trend={-3}
                  />
                  <StateCard
                    icon={CompetencyScore}
                    // value="0"
                    value={averageMetricsVacancies.c.toFixed(2)}
                    label="Kompetenzwert"
                  />
                </div>

                <div>
                  <h3 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold sm:py-[15px] border-b border-[#CCCCCC] px-5">
                    Kompetenzen{" "}
                    <span className="font-normal"> (Statistiken)</span>
                  </h3>

                  <div className="p-5">
                    <div className="grid grid-cols-1  md:grid-cols-2 gap-5 sm:gap-6">
                      {/* First Card */}
                      <div className=" bg-[#F2F6FA] py-4 px-5 rounded-[15px] flex flex-col gap-2.5">
                        <div className="flex items-center gap-2.5">
                          <h2 className="font-medium text-base leading-normal text-[#333333]">
                            Humanpotential
                          </h2>
                          <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                        </div>
                        <div className="text-3xl sm:text-4xl font-bold">
                          <CountUp
                            targetNumber={averageMetricsVacancies.h.toFixed(2)}
                          />
                        </div>
                        <h6 className="text-sm text-gray-600">Soll: 4,13</h6>
                      </div>

                      {/* Second Card */}
                      <div className=" bg-[#F2F6FA] py-4 px-5 rounded-[15px] flex flex-col gap-2.5">
                        <div className="flex items-center  gap-2.5">
                          <h2 className="font-medium text-base leading-normal text-[#333333]">
                            Engagement Faktor
                          </h2>
                          <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                        </div>
                        <div className="text-3xl sm:text-4xl font-bold">
                          1,01
                        </div>
                      </div>

                      {/* Third Card */}
                      <div className=" bg-[#F2F6FA] py-4 px-5 rounded-[15px] flex flex-col gap-2.5">
                        <div className="flex items-center  gap-2.5">
                          <h2 className="font-medium text-base leading-normal text-[#333333]">
                            Spezifität
                          </h2>
                          <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                        </div>
                        <div className="text-3xl sm:text-4xl font-bold">
                          {averageMetricsVacancies.mu.toFixed(2)}
                        </div>
                        <div className="text-sm text-gray-600">Soll: 0,369</div>
                      </div>

                      {/* Fourth Card */}
                      <div className=" bg-[#F2F6FA] py-4 px-5 rounded-[15px] flex flex-col gap-2.5">
                        <div className="flex items-center  gap-2.5">
                          <h2 className="font-medium text-base leading-normal text-[#333333]">
                            Kompetenzabdeckungsgrad
                          </h2>
                          <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                        </div>
                        <div>
                          <CircularProgressChart
                            percentage={70}
                            height={60}
                            width={60}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 bg-[#F2F6FA] p-4 rounded-[15px] flex flex-col gap-2.5">
                      <ColorBar value={averageMetricsVacancies.t.toFixed(2)} />
                      <div className="flex items-center  gap-2.5">
                        <h2 className="font-medium text-base leading-normal text-[#333333]">
                          Ökonomische Temperatur
                        </h2>
                        <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                      </div>
                      <p className="text-xl font-bold leading-normal">
                        <CountUp
                          targetNumber={averageMetricsVacancies.t.toFixed(2)}
                        />
                      </p>
                    </div>
                  </div>

                  {/* Kompetenzen graph */}

                  <div className="p-5">
                    <div className="chart-container bg-[#F2F6FA] rounded-[15px] p-4">
                      <div className="flex justify-between items-center px-2 sm:px-5 py-2 sm:py-[15px] border-b border-[#CCCCCC]">
                        <h3 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-normal">
                          Kompetenzen
                        </h3>
                        {/* Kompetenzprofil Button */}
                        <button className="bg-[#317A9A] hover:bg-[#16658A] text-white rounded-[5px] text-sm font-medium shadow-md transition duration-300 flex gap-2.5 px-3 py-2.5 justify-between w-[157px]">
                          Kompetenzprofil{" "}
                          <span>
                            {" "}
                            <img src={ArrowRight} alt="ArrowRight" />
                          </span>
                        </button>
                      </div>
                      {microChartData.length > 0 ? (
                        <MicroChart
                          graphData={microChartData}
                          isEditable={!isNonEditableRoute}
                        />
                      ) : (
                        <div className="m-5 text-center text-gray-700 p-4">
                          No competencies available for this vacancy.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserDetailPage;

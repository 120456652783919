import { X } from "lucide-react";
import React from "react";
import ReactApexChart from "react-apexcharts";

const CompetencyDialog = ({ isOpen, setIsOpen, series, categories }) => {
  const handleClose = () => setIsOpen(false);
  const Chartoptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
        borderRadius: 5,
        barHeight: "100%",
      },
    },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: Array.isArray(categories) ? categories : [],
    },
    yaxis: {
      title: { text: "Wert" },
      tickAmount: 10,
      min: 0,
      max: 100,
      labels: {
        formatter: (val) => Math.floor(val), // Format values to integers
      },
    },
    fill: { opacity: 1 },
    legend: {
      show: false,
    },
    colors: ["#083D4E", "#009951"],
    tooltip: {
      y: {
        formatter: (val) => `${val}%`,
      },
    },
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-full max-w-4xl rounded-lg shadow-lg">
        <div className="flex justify-between items-center px-6 py-4">
          <h2 className="text-xl font-semibold">Unbekannt - Kompetenzlücken</h2>
          <X
            className="text-gray-800 hover:text-black cursor-pointer"
            size={20}
            onClick={handleClose}
          />
        </div>
        <hr />
        <div className="overflow-x-auto">
          <div
            style={{
              display: "flex",
              gap: "32px",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {series?.map((val, index) => {
              return (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "14px",
                      height: "14px",
                      borderRadius: "4px",
                      opacity: "0px",
                      backgroundColor: Chartoptions.colors[index],
                    }}
                  ></div>
                  <span className="text-sm font-Montserrat font-semibold">
                    {val.name}
                  </span>
                </div>
              );
            })}
          </div>
          <ReactApexChart
            options={Chartoptions}
            series={series}
            type="bar"
            height={600}
          />
          <span className="flex justify-center text-center font-Montserrat font-semibold text-[#317A9A] py-4 px-6">
            Kompentenz
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompetencyDialog;

import React, { useEffect, useRef } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import UserIcon from "../../assets/icon/UserIcon.svg";
import Profilebg from "../../assets/icon/profile-bg.svg";
import EmailIcon from "../../assets/icon/EmailIcon.svg";
import MobileIcon from "../../assets/icon/MobileIcon.svg";
import LocationIcon from "../../assets/icon/LocationIcon.svg";
import EditWhiteIcon from "../../assets/icon/Edit_whiteIcon.svg";
import EditProfileModal from "./EditProfileModel";
import { ReactComponent as Person } from "../../assets/icon/Person.svg";
import { ReactComponent as LockClosed } from "../../assets/icon/LockClosed.svg";
import { ReactComponent as OrganizationIcon } from "../../assets/icon/BuildingIcon.svg";
import { ReactComponent as BagIcon } from "../../assets/icon/BagIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserData,
  setActiveTab,
  setIsModalOpen,
} from "../../redux/slice/profileSlice";
import Loader from "../../components/Loader";
import Profile from "./Tabs/Profil";
import Security from "./Tabs/Security";
import JobListings from "./Tabs/Jobs";
import CompanyCard from "./Tabs/CompanyCard";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeTab, userData, isModalOpen, loading, error } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
  };
  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`);
    }
  }, [error]);

  useEffect(() => {
    if (location.state?.scrollToContent) {
      setTimeout(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [location]);

  const tabData = [
    { label: "Profil", icon: Person },
    { label: "Sicherheit", icon: LockClosed },
    { label: "Besuchte Jobs", icon: BagIcon },
    { label: "Kontaktierte Unternehmen", icon: OrganizationIcon },
  ];

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    const match = phone.match(/^\+?(\d{1,4})\s?(\d+)$/);
    if (match) {
      const [, countryCode, number] = match;
      return `+${countryCode} ${number}`;
    }
    return phone;
  };

  return (
    <>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                Mein Profil
              </h2>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="bg-white p-5 rounded-[20px]">
                <div className="bg-[#F2F6FA] p-5 rounded-[15px]">
                  <div className="md:h-[200px] h-[300px] bg-[#317A9A] relative rounded-[15px]">
                    <img
                      src={Profilebg}
                      alt="Profile"
                      className="absolute top-0 left-0"
                    />
                  </div>
                  <div className="flex justify-between xl:items-end gap-5 xl:flex-row flex-col -mt-20 relative xl:ml-10">
                    <div className="bg-white p-[15px] rounded-full w-[160px] max-w-fit xl:m-0 mx-auto">
                      <img
                        src={userData?.profileImage || UserIcon}
                        alt="Profile"
                        className="w-[130px] h-[130px] rounded-full object-cover"
                      />
                    </div>
                    <h2 className="text-[22px] text-[#333333] font-bold mb-[10px] text-center block xl:hidden">
                      {userData?.username}
                    </h2>
                    <div className="flex md:items-center justify-between gap-[20px] md:flex-row flex-col xl:w-[calc(100%-160px)] w-full">
                      <div>
                        <h2 className="text-2xl text-[#333333] font-bold mb-[10px] xl:block hidden">
                          {userData?.username}
                        </h2>
                        <div className="flex lg:items-center lg:flex-row flex-col lg:gap-10 gap-[10px]">
                          <div className="text-base text-[#333333] font-medium flex items-center gap-[10px]">
                            <img
                              src={EmailIcon}
                              alt="emailicon"
                              height={16}
                              width={20}
                            />
                            {userData?.email}
                          </div>
                          <div className="text-base text-[#333333] font-medium flex items-center gap-[10px]">
                            <img
                              src={MobileIcon}
                              alt="mobileicon"
                              height={16}
                              width={20}
                            />
                            {formatPhoneNumber(userData?.phone)}
                          </div>
                          <div className="text-base text-[#333333] font-medium flex items-center gap-[10px]">
                            <img
                              src={LocationIcon}
                              alt="locationicon"
                              height={16}
                              width={20}
                            />
                            {userData?.city}
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
                          onClick={() => dispatch(setIsModalOpen(true))}
                        >
                          <img
                            src={EditWhiteIcon}
                            alt="editicon"
                            height={16}
                            width={20}
                          />
                          Profil bearbeiten
                        </button>
                        <EditProfileModal
                          id={userData?.id}
                          isOpen={isModalOpen}
                          onClose={() => dispatch(setIsModalOpen(false))}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-[#F2F6FA] p-5 rounded-[15px] my-5 flex items-center gap-5 flex-wrap">
                  {tabData.map((tab) => (
                    <button
                      key={tab.label}
                      onClick={() => handleTabClick(tab?.label)}
                      className={`px-[10px] py-2 flex items-center gap-[10px] rounded-[5px] text-base ${
                        activeTab === tab.label
                          ? "bg-[#BF5547] text-white"
                          : "text-[#888888] bg-white"
                      }`}
                    >
                      <tab.icon
                        className={`stroke-current ${
                          activeTab === tab.label
                            ? "text-white"
                            : "text-[#888888]"
                        }`}
                      />
                      {tab.label}
                    </button>
                  ))}
                </div>

                <div className="">
                  {activeTab === "Profil" && <Profile userData={userData} />}
                  {activeTab === "Sicherheit" && <Security />}
                  {activeTab === "Besuchte Jobs" && <JobListings />}
                  {activeTab === "Kontaktierte Unternehmen" && <CompanyCard />}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfilePage;

import React, { useState } from "react";
import { InputField } from "../../../components/InputField";
import MainLogo from "../../../assets/skills_logo.svg.svg";
import Select from "../../../components/Select";
import PasswordInput from "../../../components/PasswordInput";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/slice/userSlice";
import DefaultAuthLayout from "../../../components/AuthLayout";
import { useNavigate } from "react-router-dom";
import locations from "../../../pages/Auth/Register/location.json";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Bitte geben Sie eine gültige E-Mail-Adresse ein")
      .required("E-Mail ist erforderlich")
      .max(255, "E-Mail darf maximal 255 Zeichen lang sein")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Ungültiges E-Mail-Format"
      ),
    password: Yup.string()
      .required("Passwort ist erforderlich")
      .min(8, "Passwort muss mindestens 8 Zeichen lang sein")
      .max(50, "Passwort darf maximal 50 Zeichen lang sein")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten"
      ),
    country: Yup.string().required("Land ist erforderlich"),
    state: Yup.string().when('country', {
      is: (country) => country && country.length > 0,
      then: () => Yup.string().required("Bundesland ist erforderlich"),
      otherwise: () => Yup.string().notRequired(),
    }),
    district: Yup.string().when(['country', 'state'], {
      is: (country, state) => country && country.length > 0 && state && state.length > 0,
      then: () => Yup.string().required("Bezirk ist erforderlich"),
      otherwise: () => Yup.string().notRequired(),
    }),
    city: Yup.string()
      .required("Stadt/Ort ist erforderlich")
      .min(2, "Stadt/Ort muss mindestens 2 Zeichen lang sein")
      .max(100, "Stadt/Ort darf maximal 100 Zeichen lang sein")
      .matches(
        /^[a-zA-ZäöüßÄÖÜ\s-]+$/,
        "Stadt/Ort darf nur Buchstaben, Leerzeichen und Bindestriche enthalten"
      ),
    zipcode: Yup.string()
      .required("Postleitzahl ist erforderlich")
      .matches(/^\d{4}$/, "Postleitzahl muss genau 4 Ziffern enthalten")
  });

  const handleRegister = async (values, { resetForm, setFieldError }) => {
    try {
      const resultAction = await dispatch(registerUser(values));
      if (registerUser.fulfilled.match(resultAction)) {
        toast.success("Registrierung erfolgreich!");
        navigate("/", { replace: true });
        resetForm();
      } else if (registerUser.rejected.match(resultAction)) {
        if (resultAction.payload?.includes("email")) {
          setFieldError("email", "Diese E-Mail-Adresse wird bereits verwendet");
        } else {
          toast.error(resultAction.payload || "Registrierung fehlgeschlagen");
        }
      }
    } catch (err) {
      console.error("Fehler bei der Registrierung", err);
      toast.error("Ein unerwarteter Fehler ist aufgetreten");
    }
  };
  // const selectOptions = [
  //   { value: "einzelperson", label: "Einzelperson" },
  //   { value: "unternehmen", label: "Unternehmen" }
  // ];

  const handleCountryChange = (value, setFieldValue, validateForm) => {
    setFieldValue("country", value, false); // Don't validate immediately
    const countryStates = locations[value]?.states || {};
    setStates(Object.keys(countryStates));
    setDistricts([]);
    setFieldValue("state", "", false);
    setFieldValue("district", "", false);
    
    // Delay validation until after state updates
    setTimeout(() => {
      validateForm();
    }, 0);
  };
  const handleStateChange = (value, setFieldValue, country, validateForm) => {
    setFieldValue("state", value, false); // Don't validate immediately
    const countryStates = locations[country]?.states || {};
    setDistricts(countryStates[value] || []);
    setFieldValue("district", "", false);
    
    // Delay validation until after state updates
    setTimeout(() => {
      validateForm();
    }, 0);
  };

  return (
    <div className="flex items-center">
      <DefaultAuthLayout />
      <div className="lg:w-[40%] md:w-[50%] w-full items-center h-screen bg-white">
        <div className="w-full max-w-[500px] mx-auto sm:px-8 p-6 bg-white mt-5 mb-10">
          <div className="text-center">
            <img
              src={MainLogo}
              alt="8Skills Logo"
              className="mx-auto mb-[30px]"
            />
            <h2 className="md:text-2xl text-xl font-bold text-[#333]">
              Anmelden
            </h2>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              // usertype: "",
              country: "",
              state: "",
              district: "",
              city: "",
              zipcode: ""
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              setFieldValue,
              validateForm,
              isSubmitting
            }) => (
              <Form>
                <div className="mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Email
                  </label>
                  <InputField
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Geben Sie Ihren Benutzernamen ein"
                  />
                  {touched.email && errors.email ? (
                    <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Password
                  </label>
                  <div className="relative mb-3">
                    <PasswordInput
                      name="password"
                      placeholder="Geben Sie Ihr Passwort ein"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.password && errors.password ? (
                      <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
{/* 
                <div className="relative mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Du bist
                  </label>
                  <Select
                    options={selectOptions}
                    value={values.usertype}
                    onChange={(e) => setFieldValue("usertype", e.target.value)}
                    placeholder="Auswahl"
                  />
                  {touched.usertype && errors.usertype ? (
                    <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                      {errors.usertype}
                    </div>
                  ) : null}
                </div> */}
                <div className="flex gap-[19.92px]">
                  <div className="relative mb-5 w-1/2">
                    <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                      Land
                    </label>
                    <Select
                      options={Object.keys(locations).map((country) => ({
                        value: country,
                        label: country
                      }))}
                      value={values.country}
                      onChange={(e) =>
                        handleCountryChange(e.target.value, setFieldValue, validateForm)
                      }
                      placeholder="Auswahl"
                    />
                    {touched.country && errors.country && (
                      <div className="text-red-500 text-sm m-2">
                        {errors.country}
                      </div>
                    )}
                  </div>
                  <div className="relative mb-5 w-1/2">
                    <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                      Bundesland
                    </label>
                    <Select
                      options={states.map((state) => ({
                        value: state,
                        label: state
                      }))}
                      value={values.state}
                      onChange={(e) =>
                        handleStateChange(
                          e.target.value,
                          setFieldValue,
                          values.country,
                          validateForm
                        )
                      }
                      placeholder="Auswahl"
                      disabled={!values.country}
                    />
                    {touched.state && errors.state && (
                      <div className="text-red-500 text-sm m-2">
                        {errors.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-[19.92px]">
                  <div className="relative mb-5 w-1/2">
                    <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                      Bezirk
                    </label>
                    <Select
                      options={districts.map((district) => ({
                        value: district,
                        label: district
                      }))}
                      value={values.district}
                      onChange={(e) =>
                        setFieldValue("district", e.target.value)
                      }
                      placeholder="Auswahl"
                      disabled={!values.state}
                    />
                    {touched.district && errors.district && (
                      <div className="text-red-500 text-sm m-2">
                        {errors.district}
                      </div>
                    )}
                  </div>
                  <div className="relative mb-5 w-1/2">
                    <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                      Postleitzahl
                    </label>
                    <InputField
                      className="text-[14px]"
                      type="zipcode"
                      name="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Geben Sie Ihre Postleitzahl ein"
                    />
                    {touched.zipcode && errors.zipcode ? (
                      <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                        {errors.zipcode}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Stadt/Ort
                  </label>
                  <InputField
                    type="city"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Geben Sie Ihre Stadt/Ihren Standort ein"
                  />
                  {touched.city && errors.city ? (
                    <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                      {errors.city}
                    </div>
                  ) : null}
                </div>
                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="max-w-[190px] w-full bg-[#BF5547] text-white text-base leading-5 py-3 px-7 rounded-md"
                  >
                    {loading || isSubmitting ? (
                      <div className="btnLoading outline-none">
                        <Loader />
                      </div>
                    ) : (
                      "Anmelden"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="mt-7 text-center text-sm text-[#888888]">
            Haben Sie ein Konto??{" "}
            <a href="/" className="text-[#083D4E] font-medium hover:underline">
              Melden Sie sich hier an
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getVisiblePages = () => {
    const visiblePages = [];
    const range = 1;

    for (
      let page = Math.max(1, currentPage - range);
      page <= Math.min(totalPages, currentPage + range);
      page++
    ) {
      visiblePages.push(page);
    }

    return visiblePages;
  };

  return (
    <div className="flex justify-center items-center space-x-2 py-4 border-t-2 border-neutral-200">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-4 py-2 text-sm font-semibold rounded-xl ${
          currentPage === 1
            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
            : "bg-[#083D4E] text-white hover:bg-[#0f6a87]"
        }`}
      >
        Prev
      </button>

      {/* First page */}
      {currentPage > 3 && (
        <>
          <button
            onClick={() => onPageChange(1)}
            className="px-4 py-2 text-sm font-semibold rounded-xl bg-gray-200 text-gray-700 hover:bg-gray-300"
          >
            1
          </button>
          <span className="text-gray-500">...</span>
        </>
      )}

      {/* Visible pages */}
      {getVisiblePages().map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`px-4 py-2 text-sm font-semibold rounded-xl ${
            currentPage === page
              ? "bg-[#317A9A] text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          {page}
        </button>
      ))}

      {/* Last page */}
      {currentPage < totalPages - 2 && (
        <>
          <span className="text-gray-500">...</span>
          <button
            onClick={() => onPageChange(totalPages)}
            className="px-4 py-2 text-sm font-semibold rounded-xl bg-gray-200 text-gray-700 hover:bg-gray-300"
          >
            {totalPages}
          </button>
        </>
      )}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-4 py-2 text-sm font-semibold rounded-xl ${
          currentPage === totalPages
            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
            : "bg-[#083D4E] text-white hover:bg-[#0f6a87]"
        }`}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

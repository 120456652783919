import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import EditIcon from "../../../assets/icon/EditIcon.svg";
import deleteIcon from "../../../assets/icon/deleteIcon.svg";
import RightArrow from "../../../assets/icon/right-arrow.svg";

const SeminarDetailsPage = () => {
  const { loading } = useSelector((state) => state.vacancies);
  return (
    <>
      <Header />

      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <div className="flex flex-col gap-7">
                <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                  Seminar
                </h2>
                <span>testsemirna</span>
              </div>
              <div className="flex space-x-2">
                <button className="bg-[#FFFFFF] text-black text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                  <img src={EditIcon} alt="Globe" />
                  Bearbeiten
                </button>
                <button className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                  <img src={deleteIcon} alt="Globe" />
                  Löschen
                </button>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="bg-white p-5 rounded-[20px]">
                <div className="grid grid-cols-[1fr,465px,465px] gap-5">
                  <div>
                    <div className="flex justify-between items-center px-2 sm:px-5 py-2 sm:py-[15px] border-b border-[#CCCCCC]">
                      <h3 className="text-base sm:text-lg font-semibold">
                        Übersicht
                      </h3>
                    </div>
                    <div className="p-4 sm:p-5 grid gap-4 sm:gap-5">
                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Name
                        </h4>
                        <div className="flex flex-wrap gap-2"></div>
                      </div>

                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Seminartyp
                        </h4>
                        <div className="flex flex-wrap gap-2"></div>
                      </div>

                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Beschreibung
                        </h4>
                      </div>

                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Preis
                        </h4>
                        <div className="flex flex-wrap gap-2"></div>
                      </div>

                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Startdatum
                        </h4>
                        <div className="flex flex-wrap gap-2"></div>
                      </div>

                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Enddatum
                        </h4>
                        <div className="flex flex-wrap gap-2"></div>
                      </div>

                      <div>
                        <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                          Ort
                        </h4>
                        <div className="flex flex-wrap gap-2"></div>
                      </div>
                    </div>
                  </div>

                  {/* Right Column */}
                  <div>
                    <div className="flex justify-between items-center px-2 sm:px-5 py-2 sm:py-[15px] border-b border-[#CCCCCC]">
                      <h3 className="text-base sm:text-lg font-semibold">
                        3 Kompetenzen
                      </h3>
                    </div>

                    <div className="lg:col-span-1 bg-[#F2F6FA] rounded-[15px] shadow p-6 mt-5">
                      <div className="flex flex-col gap-2">
                        <div className="rounded-md py-3.5 px-[21px] text-[#333333] cursor-pointer  bg-[#FFFFFF] flex items-center justify-between">
                          Augmented Reality
                          <img src={RightArrow} alt="Globe" />
                        </div>
                        <div className="rounded-md py-3.5 px-[21px] text-[#333333] cursor-pointer  bg-[#FFFFFF] flex items-center justify-between">
                          3-D Druck
                          <img src={RightArrow} alt="Globe" />
                        </div>
                        <div className="rounded-md py-3.5 px-[21px] text-[#333333] cursor-pointer  bg-[#FFFFFF] flex items-center justify-between">
                          Anpassungsfähigkeit
                          <img src={RightArrow} alt="Globe" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right Column - Teilnehmer */}
                  <div className="lg:col-span-1 bg-white rounded-[10px]">
                    <h4 className="text-lg font-semibold mb-4  border-b border-[#CCCCCC] px-2 sm:px-5 py-2 sm:py-[15px]">
                      0 Teilnehmer
                    </h4>
                    <div className="bg-[#F2F6FA] p-6 mt-5 rounded-[15px]">
                      <input
                        type="text"
                        placeholder="Wir haben keine Daten"
                        className="border w-full rounded-[6px] min-h-[166px] text-center text-[#888888] text-sm font-medium outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SeminarDetailsPage;

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const tokenExpiry = JSON.parse(atob(token.split(".")[1])).exp * 1000; // Decode expiry
    const now = Date.now();

    if (now >= tokenExpiry) {
      localStorage.removeItem("token"); // Clear expired token
      return <Navigate to="/" />;
    }

    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export default PrivateRoute;

// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";

// const PrivateRoute = () => {
//   const token = localStorage.getItem("token"); // Check if token exists

//   return token !== undefined && token !== null ? <Outlet /> : <Navigate to="/" />;
// };

// export default PrivateRoute ;

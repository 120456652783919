import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useGetApi } from "../../hook/useApiHook";

const initialState = {
  scalings: [],
  loading: false,
  error: null,
};

export const fetchScalings = createAsyncThunk(
  "scaling/fetchScalings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await useGetApi(`${process.env.REACT_APP_API_URL}/users/scalings`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || "Failed to fetch scalings");
    }
  }
);

const scalingSlice = createSlice({
  name: "scaling",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchScalings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchScalings.fulfilled, (state, action) => {
        state.loading = false;
        state.scalings = action.payload;
      })
      .addCase(fetchScalings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default scalingSlice.reducer;

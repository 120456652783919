import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { useHumaticsCalculations } from "../../../hook/useHumaticsCalculations";
import { ReactComponent as InfoIcon } from "../../../assets/icon/InfoIcon.svg";
import MicroChart from "../../../components/Charts/MicroChart";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobsUserProfile } from "../../../redux/slice/jobsUsersSlice";
import ColorBar from "../../../components/Charts/ColorBarGraph";
import CountUp from "../../../components/CountUp";
import { fetchVacancies } from "../../../redux/slice/vacanciesSlice";
import EditIcon from "../../../assets/icon/EditIcon.svg";
import deleteIcon from "../../../assets/icon/deleteIcon.svg"

const CompetenciesDetailPage = () => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { jobsUserProfile } = useSelector((state) => state.jobsUser);
    const { vacancies } = useSelector((state) => state.vacancies);
    const [selectedUser, setSelectedUser] = useState(null);
    const [chartData, setChartData] = useState([]);
    const { calculateMetrics } = useHumaticsCalculations();
    const isNonEditableRoute = window.location.href.includes(`/ProfileCompetencies/${id}`);

    useEffect(() => {
        dispatch(fetchJobsUserProfile());
        dispatch(fetchVacancies());
    }, [dispatch]);

    useEffect(() => {
        if (jobsUserProfile && id) {
            const foundUser = jobsUserProfile.find((user) => user.userId === parseInt(id));

            if (foundUser) {
                setSelectedUser(foundUser);

                // Transform skills into chart data format
                const newChartData = foundUser.skills.map((skill) => ({
                    skill: skill.skillName,
                    percentage: skill.percentage,
                }));
                setChartData(newChartData);
            }
        }
    }, [jobsUserProfile, id]);

    // Calculate average metrics from user's skills
    const averageMetricsVacancies = vacancies.reduce(
        (acc, position) => {
            const metrics = calculateMetrics(
                position.competences.map((c) => c.value)
            );
            return {
                h: metrics.h,
                t: metrics.t,
                r: metrics.r,
                i: metrics.i,
                c: metrics.c,
                competencies: metrics.competencies,
                mu: metrics.mu,
            };
        },
        { h: 0, t: 0, r: 0, i: 0, c: 0, mu: 0 }
    );

    const ScoreCard = ({ title, score, icon }) => (
        <div className="bg-[#F2F6FA] p-4 rounded-lg">
            <div className="flex items-center gap-2.5 mb-2">
                <h3 className="text-sm font-medium text-gray-500">{title}</h3>
                {icon}
            </div>
            <p className="text-2xl font-bold">
                <CountUp targetNumber={score.toFixed(2)} />
            </p>
        </div>
    );

    const handleSkillUpdate = () => { };

    return (
        <>
            <Header />
            <div className="bg-[#F2F6FA] min-h-screen">
                <div className="container">
                    <div className="py-[60px] px-[10px]">
                        <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
                            <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                                {selectedUser ? `Competencies - ${selectedUser.username || "Unknown User"}` : 'Competencies'}
                            </h2>
                            <div className="flex space-x-2">
                                <button className="bg-[#FFFFFF] text-black text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                                    <img src={EditIcon} alt="Globe" />
                                    Bearbeiten
                                </button>
                                <button className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                                    <img src={deleteIcon} alt="Globe" />
                                    Löschen
                                </button>
                            </div>
                        </div>

                        {selectedUser ? (
                            <div className="bg-white p-5 rounded-[20px]">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                                    <ScoreCard
                                        title="Humanpotential"
                                        score={averageMetricsVacancies.h}
                                        icon={<InfoIcon className="w-4 h-4 text-gray-400" />}
                                    />
                                    <div className="bg-[#F2F6FA] p-4 rounded-lg">
                                        <ColorBar value={averageMetricsVacancies.t.toFixed(2)} />
                                        <div className="flex items-center gap-2.5 mb-2 mt-2.5">
                                            <h3 className="text-sm font-medium text-gray-500">
                                                Ökonomische Temperatur
                                            </h3>
                                            <InfoIcon className="w-4 h-4 text-gray-400" />
                                        </div>
                                        <p className="text-2xl font-bold mb-2">
                                            {averageMetricsVacancies.t.toFixed(2)}
                                        </p>
                                    </div>
                                    <ScoreCard
                                        title="Spezifität"
                                        score={averageMetricsVacancies.mu}
                                        icon={<InfoIcon className="w-4 h-4 text-gray-400" />}
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row gap-5 items-center mx-auto p-6 bg-[#F2F6FA] max-w-[1620px] rounded-lg">
                                    <div className="chart-container w-full md:w-3/4 mb-6 md:mb-0">
                                        <MicroChart
                                            graphData={chartData}
                                            onSkillUpdate={handleSkillUpdate}
                                            isEditable={!isNonEditableRoute}
                                        />
                                    </div>
                                    <div className="legend w-full md:w-1/4 md:ml-6 text-sm text-gray-500 mt-20">
                                        <p className="mb-2">80 % NI - Experte</p>
                                        <p className="mb-2">50 % NI - Umfassend</p>
                                        <p className="mb-2">40 % NI - Sicher</p>
                                        <p className="mb-2">30 % NI - Anwender</p>
                                        <p className="mb-2">20 % NI - Grundkenntnisse</p>
                                        <p className="mb-2">10 % NI - Keine Kenntnisse</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white p-5 rounded-[20px] text-center">
                                No user selected
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CompetenciesDetailPage;
import React, { useEffect, useState } from "react";
import { InputField } from "../../../components/InputField";
import { ReactComponent as DropdownArrow } from "../../../assets/icon/DownArrow.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icon/SaveIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icon/CloseIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserData,
  updateUserProfile,
} from "../../../redux/slice/profileSlice";

export default function ExperienceEditForm({
  isOpen,
  onClose,
  onSaveSuccess,
  experienceData,
  isNewExperience,
}) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.profile);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    isCurrent: false,
    company: "",
    position: "",
    location: "",
  });

  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
    company: "",
    position: "",
  });

  const formatDateToInput = (dateString) => {
    if (!dateString || dateString.includes("-")) return dateString;
    const [day, month, year] = dateString.split(".");
    if (day && month && year) {
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  const formatDateToServer = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const validateCompanyName = (name) => {
    const trimmedName = name.trim();

    if (!trimmedName) {
      return "Firmenname ist erforderlich";
    }

    if (/^\d+$/.test(trimmedName)) {
      return "Firmenname darf nicht nur aus Zahlen bestehen";
    }

    const hasLetters = /[a-zA-Z]/.test(trimmedName);
    // const hasNumbers = /\d/.test(trimmedName);
    
    if (!hasLetters) {
      return "Firmenname muss sowohl Buchstaben als auch Zahlen enthalten";
    }

    return "";
  };

  useEffect(() => {
    if (!isNewExperience && experienceData) {
      setFormData({
        startDate: formatDateToInput(experienceData?.startdate || ""),
        endDate: formatDateToInput(experienceData?.enddate || ""),
        company: experienceData?.company || "",
        position: experienceData?.position || "",
        location: experienceData?.location || "",
        isCurrent: experienceData?.isCurrent || false,
      });
    } else {
      setFormData({
        startDate: "",
        endDate: "",
        isCurrent: false,
        company: "",
        position: "",
        location: "",
      });
    }
  }, [experienceData, isNewExperience]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      startDate: "",
      endDate: "",
      company: "",
      position: "",
    };

    // Required field validations
    if (!formData.startDate) {
      newErrors.startDate = "Startdatum ist erforderlich";
      isValid = false;
    }

    if (!formData.isCurrent && !formData.endDate) {
      newErrors.endDate = "Enddatum ist erforderlich";
      isValid = false;
    }

    // Company name validation
    const companyError = validateCompanyName(formData.company);
    if (companyError) {
      newErrors.company = companyError;
      isValid = false;
    }

    if (!formData.position) {
      newErrors.position = "Position ist erforderlich";
      isValid = false;
    }

    // Date logic validation
    if (formData.startDate && formData.endDate && !formData.isCurrent) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      if (startDate > endDate) {
        newErrors.startDate = "Startdatum kann nicht nach dem Enddatum liegen";
        isValid = false;
      }

      // Validate against future dates
      const today = new Date();
      if (startDate > today) {
        newErrors.startDate = "Startdatum kann nicht in der Zukunft liegen";
        isValid = false;
      }
      if (endDate > today) {
        newErrors.endDate = "Enddatum kann nicht in der Zukunft liegen";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
      ...(name === "isCurrent" && checked ? { endDate: "" } : {}),
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const newExperienceEntry = {
        startdate: formatDateToServer(formData.startDate),
        enddate: formData.isCurrent
          ? null
          : formatDateToServer(formData.endDate),
        isCurrent: formData.isCurrent,
        company: formData.company,
        position: formData.position,
        location: formData.location,
      };

      const currentExperiences = userData.experience || [];
      let updatedExperiences;

      if (isNewExperience) {
        // Add new experience to the existing array
        updatedExperiences = [...currentExperiences, newExperienceEntry];
      } else {
        // Update existing experience
        updatedExperiences = currentExperiences.map((exp) =>
          exp.startdate === experienceData.startdate &&
          exp.company === experienceData.company
            ? newExperienceEntry
            : exp
        );
      }

      await dispatch(
        updateUserProfile({
          id: userData.id,
          userData: {
            ...userData,
            experience: updatedExperiences,
          },
        })
      ).unwrap();

      await dispatch(fetchUserData());
      if (onSaveSuccess) onSaveSuccess();
      onClose();
    } catch (error) {
      console.error("Failed to save experience:", error);
    }
  };
  // const handleSubmit = async () => {
  //   try {
  //     const experienceData = {
  //       startdate: formatDateToServer(formData.startDate),
  //       enddate: formData.isCurrent
  //         ? null
  //         : formatDateToServer(formData.endDate),
  //       isCurrent: formData.isCurrent,
  //       company: formData.company,
  //       position: formData.position,
  //       location: formData.location,
  //     };

  //     const updatedExperience = [...(userData.experience || [])];

  //     if (isNewExperience) {
  //       // Add new experience at the end of the array
  //       updatedExperience.push(experienceData);
  //     } else {
  //       // Update existing experience
  //       const index = updatedExperience.findIndex(
  //         exp => exp.startdate === experienceData.startdate &&
  //                exp.company === experienceData.company
  //       );
  //       if (index !== -1) {
  //         updatedExperience[index] = experienceData;
  //       }
  //     }
  //     await dispatch(
  //       updateUserProfile({
  //         id: userData.id,
  //         userData: {
  //           ...userData,
  //           experience: updatedExperience,
  //         },
  //       })
  //     ).unwrap();

  //     await dispatch(fetchUserData());
  //     if (onSaveSuccess) onSaveSuccess();
  //     onClose();
  //   } catch (error) {
  //     console.error("Failed to save experience:", error);
  //   }
  // };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-[15px] shadow-lg max-w-[830px] w-[calc(100%-30px)] max-h-[95vh] overflow-auto">
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-2xl font-bold">Erfahrung bearbeiten</h3>
        </div>
        <div className="p-5">
          <div className="grid sm:grid-cols-2 gap-4 mb-5">
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Aus
              </label>
              <div className="relative">
                <input
                  type="date"
                  className={`w-full px-[10px] py-[9px] border rounded-[7px] ${
                    errors.startDate ? "border-red-500" : "border-[#CCCCCC]"
                  } text-sm font-normal leading-[18px]`}
                  placeholder="Wählen Sie das Startdatum"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
                {errors.startDate && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.startDate}
                  </p>
                )}
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Zu
              </label>
              <div className="relative">
                <input
                  type="date"
                  className={`w-full px-[10px] py-[9px] border rounded-[7px] ${
                    errors.endDate ? "border-red-500" : "border-[#CCCCCC]"
                  } text-sm font-normal leading-[18px]`}
                  placeholder="Wählen Sie das Enddatum aus"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                  disabled={formData.isCurrent}
                />
                {errors.endDate && (
                  <p className="text-red-500 text-sm mt-1">{errors.endDate}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mb-5">
            <label className="flex items-center w-full gap-[10px]">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                name="isCurrent"
                checked={formData.isCurrent}
                onChange={handleChange}
              />
              <div className="font-medium text-base text-[#333333]">
                Arbeite derzeit hier
              </div>
            </label>
          </div>
          <div className="grid sm:grid-cols-2 gap-4 mb-5">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name der Firma
              </label>
              <div className="relative">
                <InputField
                  type="text"
                  placeholder="Schreiben Sie den Firmennamen"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className={errors.company ? "border-red-500" : ""}
                />
                {errors.company && (
                  <p className="text-red-500 text-sm mt-1">{errors.company}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bezeichnung
              </label>
              <div className="relative">
                <select
                  className={`w-full pl-3 pr-10 py-[10px] border ${
                    errors.position ? "border-red-500" : "border-[#CCCCCC]"
                  } leading-[18px] text-sm rounded-md appearance-none`}
                  name="position"
                  value={formData.position}
                  onChange={handleChange}
                >
                  <option value="">Wählen Sie Ihre Bezeichnung aus</option>
                  <option value="manager">Manager</option>
                  <option value="developer">Developer</option>
                  <option value="designer">Designer</option>
                </select>
                {errors.position && (
                  <p className="text-red-500 text-sm mt-1">{errors.position}</p>
                )}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <DropdownArrow />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mb-5">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Firmenadresse
            </label>
            <div className="relative">
              <textarea
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="w-full pl-2 pr-3 py-2 border border-gray-300 rounded-md text-gray-400"
                rows="3"
                placeholder="Firmenadresse schreiben"
              ></textarea>
            </div>
          </div> */}
          <div className="flex justify-end sm:gap-[20px] gap-[10px]  mt-5">
            <button
              className="text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={onClose}
            >
              <CloseIcon />
              Stornieren
            </button>
            <button
              className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={handleSubmit}
            >
              <SaveIcon />
              {isNewExperience ? "Hinzufügen" : "Speichern"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

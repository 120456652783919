import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/slice/authSlice";
import { fetchUserData } from "../../redux/slice/profileSlice";
import SkillLogo from "../../assets/logo_white.svg";
import HumbergerMenu from "../../assets/icon/menuHumberger.svg";
import GlobleIcon from "../../assets/icon/GlobeIcon.svg";
import UserMenu from "../../components/UserMenu";
import ArrowDown from "../../assets/icon/Arrow_Down.svg";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userData } = useSelector((state) => state.profile);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("kompetenzen");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dropdownItems = [
    { id: "kompetenzen", label: "Kompetenzen" },
    // { id: "kompetenztypen", label: "Kompetenztypen" },
    // { id: "kompetenzlevel", label: "Kompetenzlevel" },
    // { id: "skalierungen", label: "Skalierungen" },
    // { id: "kompetenzgruppen", label: "Kompetenzgruppen" },
  ];

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      window.location.reload();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
    navigate(`/competences?tab=${id}`);
    setIsDropdownOpen(false);
  };

  const menuItems = [
    { label: "Einstellungen", href: "/profile" },
    { label: "Logout", onClick: handleLogout },
  ];

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="bg-[#083D4E] text-white">
      <div className="container mx-auto flex items-center justify-between py-[7px] h-[60px]">
        <Link to="/" className="inline-block w-[104px] relative z-20">
          <img src={SkillLogo} alt="SKILLS Logo" className="w-full" />
        </Link>
        <button
          className="w-[40px] h-[40px] rounded-full bg-gray-700 text-white lg:hidden block"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img src={HumbergerMenu} alt="Menu" className="w-full" />
        </button>
        <ul
          className={`lg:flex lg:relative lg:bg-transparent lg:w-auto left-0 lg:top-0 p-5 items-center xl:gap-[30px] z-10 gap-3 transition
          ${
            isMenuOpen ? "block" : "hidden"
          } absolute bg-[#083D4E] w-full top-[60px]`}
        >
          <li>
            <Link
              to="/"
              className={`transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] 
                ${
                  isActiveRoute("/")
                    ? "bg-white text-[#083D4E]"
                    : "hover:bg-[#317A9A] text-white"
                }`}
            >
              Home
            </Link>
          </li>
          <li className="relative">
            <button
              id="kompetenz-trigger"
              className={`transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] 
                ${
                  isActiveRoute("/competences")
                    ? "bg-white text-[#083D4E]"
                    : "hover:bg-[#317A9A] text-083D4E"
                }
                flex items-center gap-2`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span>Kompetenzen</span>
              <img
                src={ArrowDown}
                alt="ArrowDown"
                className={`w-4 h-4 transition-transform duration-200 ${
                  isActiveRoute("/competences")
                    ? ""
                    : "filter brightness-0 invert"
                }
                  ${isDropdownOpen ? "rotate-180" : ""}`}
              />
            </button>
            {isDropdownOpen && (
              <ul
                id="kompetenz-dropdown"
                className="absolute left-0 top-full mt-2 bg-white rounded-lg shadow-lg z-10 min-w-[200px] py-1"
              >
                {dropdownItems.map((item) => (
                  <li key={item.id}>
                    <button
                      className={`block w-full text-left px-4 py-2 text-black hover:text-white hover:bg-[#317a9a] ${
                        activeTab === item.id ? "bg-[#083d4e] text-white" : ""
                      }`}
                      onClick={() => handleTabChange(item.id)}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <Link
              to="/jobs"
              className={`transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] 
                ${
                  isActiveRoute("/jobs")
                    ? "bg-white text-[#083D4E]"
                    : "hover:bg-[#317A9A] text-white"
                }`}
            >
              Arbeitsplätze
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className={`transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] 
                ${
                  isActiveRoute("/")
                    ? "bg-white text-[#083D4E]"
                    : "hover:bg-[#317A9A] text-white"
                }`}
            >
              Mitarbeiter
            </Link>
          </li>
        </ul>
        <div className="relative lg:block hidden">
          <div className="flex items-center space-x-4">
            <button className="w-[40px] h-[40px] rounded-full bg-gray-700 text-white">
              <img src={GlobleIcon} alt="Globe" className="w-full" />
            </button>
            <UserMenu
              username="admin"
              menuItems={menuItems}
              profilePic={userData?.profileImage}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

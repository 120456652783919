import React from "react";
import ReactApexChart from "react-apexcharts";

const CompetencyApexBarChart = ({ colors, series, categories }) => {
  const Chartoptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        borderRadius: 4,
        borderRadiusWhenStacked: "all",
        horizontal: false,
        barHeight: "100%",
      },
    },
    dataLabels: { enabled: false },
    stroke: { show: false },
    colors: colors,
    grid: {
      show: false,
      padding: { left: 0, right: 0 },
    },
    xaxis: {
      categories: Array.isArray(categories) ? categories : [0],
      labels: { show: false },
      axisBorder: { show: false },
    },
    yaxis: { show: false },
    tooltip: { enabled: false },
    legend: { show: false },
  };

  return (
    <ReactApexChart
      options={Chartoptions}
      series={series}
      type="bar"
      height={130}
      width={165}
    />
  );
};

export default CompetencyApexBarChart;

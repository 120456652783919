/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import Header from "../../layout/Header";
import UsersIcon from "../../assets/icon/UsersIcon.svg";
import GraphUpIcon from "../../assets/icon/GraphUpIcon.svg";
import InnovativePower from "../../assets/icon/InnovativePower.svg";
import RedundancyIcon from "../../assets/icon/BagIcon.svg";
import CompetencyScore from "../../assets/icon/CompetencyScoreIcon.svg";
import CompetencyScoreIcon from "../../assets/icon/CompetenciesIcon.svg";
import JobsIcon from "../../assets/icon/JobsIcon.svg";
import EmployeeIcon from "../../assets/icon/EmployeesIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icon/SearchIcon.svg";
import { ReactComponent as Handshake } from "../../assets/icon/Handshake.svg";
import { ReactComponent as EarthIcon } from "../../assets/icon/EarthIcon.svg";
import AddIcon from "../../assets/icon/Add.svg";
import Footer from "../../layout/Footer";
import { SidebarCard } from "./SideBarCard";
import DownArrow from "../../assets/icon/DownArrow.svg";
import StateCard from "./StateCard";
import EmployeeCompetenciesDashboard from "./EmployeeCompetencies";
import WorldMap from "./WorldMap";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancies } from "../../redux/slice/vacanciesSlice";
import toast from "react-hot-toast";
import CustomApexBarChart from "../../components/Charts/CustomApexBarChart";
import { fetchSelectedSkills } from "../../redux/slice/skillSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useHumaticsCalculations } from "../../hook/useHumaticsCalculations";
import { fetchIndustries } from "../../redux/slice/industriesSlice";
import CircularProgressChart from "../../components/Charts/CircularProgressChart";
import CompetencyDialog from "../../components/Charts/CompetenceChartDialog";
import CompetencyApexBarChart from "../../components/CompetencyApexBarChart";
import { fetchJobsUserProfile } from "../../redux/slice/jobsUsersSlice";
import MicroChart from "../../components/Charts/MicroChart";
import { fetchUserData } from "../../redux/slice/profileSlice";
import userProfileImg from "../../assets/icon/userProfile.png";
import Sidebar from "../../components/Sidebar";
import { useIndustry } from "../../contextAPI/IndustryContext";
import { useTab } from "../../contextAPI/TabContext";

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    selectedIndustry,
    selectedJobs,
    selectedJob,
    filteredVacancies,
    updateIndustry,
    updateJob,
  } = useIndustry();
  const { activeTab, updateActiveTab } = useTab();
  // const [activeTab, setActiveTab] = useState(() => {
  //   return sessionStorage.getItem("dashboardActiveTab") || "matching";
  // });
  const { vacancies, loading, error } = useSelector((state) => state.vacancies);
  const { industries, status } = useSelector((state) => state.industries);
  const { userData } = useSelector((state) => state.profile);
  // const [selectedIndustry, setSelectedIndustry] = useState("");
  const [jobs, setJobs] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenMicro, setIsDialogOpenMicro] = useState(false);
  const handleOpenDialog = () => setIsDialogOpen(true);
  const { calculateMetrics } = useHumaticsCalculations();
  const [chartData, setChartData] = useState([]);
  const { jobsUserProfile } = useSelector((state) => state.jobsUser);
  // const [filteredVacancies, setFilteredVacancies] = useState([]);
  const [selectedVacancyData, setSelectedVacancyData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarData, setSidebarData] = useState({
    title: "",
    skillName: "",
    content: null,
  });
  const handleTabClick = (tabId) => {
    updateActiveTab(tabId);
  };
  const handleOpenSidebar = ({ data }) => {
    setIsSidebarOpen(true);
    setSidebarData(data);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // useEffect(() => {
  //   sessionStorage.setItem("dashboardActiveTab", activeTab);
  // }, [activeTab]);
  useEffect(() => {
    if (isDialogOpen || isDialogOpenMicro) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isDialogOpen, isDialogOpenMicro]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchIndustries());
    }
  }, [dispatch, status]);

  useEffect(() => {
    dispatch(fetchVacancies());
    dispatch(fetchJobsUserProfile());
    dispatch(fetchSelectedSkills());
    dispatch(fetchUserData());
  }, [dispatch]);

  const averageMetricsVacancies = vacancies?.reduce(
    (acc, position) => {
      const metrics = calculateMetrics(
        position?.competences?.map((c) => c.value)
      );
      return {
        h: metrics.h,
        t: metrics.t,
        r: metrics.r,
        i: metrics.i,
        c: metrics.c,
        competencies: metrics.competencies,
      };
    },
    { h: 0, t: 0, r: 0, i: 0, c: 0 }
  );

  const userProfile = jobsUserProfile?.find(
    (profile) => profile.userId === userData.id
  );
  const averageMetricsUser = useMemo(() => {
    if (!userProfile?.skills?.length) {
      return {
        h: 0,
        t: 0,
        r: 0,
        i: 0,
        c: 0,
        competencies: 0,
        mu: 0,
      };
    }
    const competencyValues = userProfile.skills.map(
      (skill) => skill.percentage
    );
    const metrics = calculateMetrics(competencyValues);

    return {
      h: metrics.h,
      t: metrics.t,
      r: metrics.r,
      i: metrics.i,
      c: metrics.c,
      competencies: metrics.competencies,
      mu: metrics.mu,
    };
  }, [userProfile, calculateMetrics]);

  const calculateHumanpotential = useMemo(() => {
    const vacancyH = averageMetricsVacancies?.h || 0;
    const userH = averageMetricsUser?.h || 0;

    // Prevent division by zero
    if (vacancyH === 0) return 0;

    // Calculate the difference percentage
    // If vacancy H is 4 and user H is 2, then: (2/4) * 100 = 50%
    const percentage = (userH / vacancyH) * 100;

    // Ensure the percentage is between 0 and 100
    return Math.min(Math.max(percentage, 0), 100);
  }, [averageMetricsVacancies?.h, averageMetricsUser?.h]);

  const navigate = useNavigate();
  const selectedSkills = useSelector(
    (state) => state?.skills?.selectedSkill?.skills
  );

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`);
    }
  }, [error]);

  const tabs = [
    { id: "matching", label: "Matching", icon: Handshake },
    { id: "search", label: "Suchen", icon: SearchIcon },
    { id: "competencymap", label: "Kompetenzkarte", icon: EarthIcon },
  ];

  const handleBarClick = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const handleClick = () => {
    navigate("/profile", { state: { scrollToContent: true } });
  };

  const handleIndustryChange = (e) => {
    const selectedId = parseInt(e.target.value);
    const industry = industries.find((ind) => ind.id === selectedId);
    updateIndustry(selectedId, industry ? industry.jobs : []);
    updateJob("", vacancies);
  };

  const handleJobChange = (event) => {
    const jobName = event.target.value;
    updateJob(jobName, vacancies);
  };

  const userIdFromSkills = selectedSkills?.[0]?.userId;
  const matchedUserProfile = jobsUserProfile?.find(
    (profile) => profile.userId === userIdFromSkills
  );

  const userSkills =
    matchedUserProfile?.skills?.reduce((acc, s) => {
      acc[s.skillName] = Number(s.percentage) || 0;
      return acc;
    }, {}) || {};

  // useEffect(() => {
  //   if (vacancies?.length > 0 && jobsUserProfile?.length > 0) {
  //     console.log(vacancies, "vacancies");
  //     const userIdFromSkills = selectedSkills?.[0]?.userId;

  //     const matchedUserProfile = jobsUserProfile?.find(
  //       (profile) => profile.userId === userIdFromSkills
  //     );

  //     const workplaceSkills =
  //       vacancies[0]?.competences?.map((c) => ({
  //         name: String(c.skill.name),
  //         value: Number(c.value) || 0,
  //       })) || [];

  //     // Initialize userSkills as an empty object if undefined
  //     const userSkills =
  //       matchedUserProfile?.skills?.reduce((acc, s) => {
  //         acc[s.skillName] = Number(s.percentage) || 0;
  //         return acc;
  //       }, {}) || {};

  //     const categories = workplaceSkills.map((c) => String(c.name));
  //     console.log(categories, ">>>>");
  //     const series = [
  //       {
  //         name: "Arbeitsplatz",
  //         data: workplaceSkills.map((c) => Math.round(c.value) || 0),
  //       },
  //       {
  //         name: "Mitarbeiter",
  //         data: workplaceSkills.map((c) => Math.round(userSkills[c.name] || 0)),
  //       },
  //     ];

  //     setChartData({
  //       series: series,
  //       categories: categories,
  //     });
  //   }
  // }, [vacancies, jobsUserProfile, selectedSkills]);

  return (
    <>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                Willkommen Admin!
              </h2>
              <button
                className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <img src={AddIcon} alt="Globe" />
                Neue Kompetenz hinzufügen
              </button>
            </div>

            <div className="bg-white p-5 rounded-[20px]">
              <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                <StateCard
                  icon={UsersIcon}
                  // value="12.3"
                  value={averageMetricsVacancies.h.toFixed(2)}
                  label="Humanpotential"
                  trend={-2}
                />
                <StateCard
                  icon={GraphUpIcon}
                  // value="0"
                  label="Ökonomische Temperatur"
                  value={averageMetricsVacancies.t.toFixed(2)}
                />
                <StateCard
                  icon={RedundancyIcon}
                  // value="2.42"
                  value={averageMetricsVacancies.r.toFixed(2)}
                  label="Redundanz"
                  trend={4}
                />
                <StateCard
                  icon={InnovativePower}
                  // value="1.24"
                  value={averageMetricsVacancies.i.toFixed(2)}
                  label="Innovationskraft"
                  trend={-3}
                />
                <StateCard
                  icon={CompetencyScore}
                  // value="0"
                  value={averageMetricsVacancies.c.toFixed(2)}
                  label="Kompetenzwert"
                />
              </div>
              <div className="flex lg:flex-row flex-col gap-5 bg-[#F2F6FA] p-5 rounded-[15px]">
                <div className="lg:w-[260px] w-full space-y-4">
                  <SidebarCard
                    icon={CompetencyScoreIcon}
                    label="Kompetenzen"
                    // value="125"
                    value={averageMetricsVacancies?.competencies}
                  />
                  <SidebarCard
                    icon={JobsIcon}
                    label="Arbeitsplätze"
                    // value="32"
                    value={vacancies.length}
                  />
                  <SidebarCard
                    icon={EmployeeIcon}
                    label="Mitarbeiter"
                    value="0"
                  />
                </div>

                <div className="lg:w-[calc(100%-280px)] w-full">
                  <div className="flex gap-5 items-center mb-5 md:flex-nowrap flex-wrap">
                    {tabs?.map((tab) => (
                      <button
                        key={tab.id}
                        className={`max-w-[160px] w-full rounded-[10px] text-sm leading-[17px] p-[9px] flex items-center justify-center gap-[14px] transition-colors duration-300 ${
                          activeTab === tab.id
                            ? "bg-white text-[#317A9A] border-b-2 border-[#317A9A]"
                            : "bg-[#FFFFFF] text-gray-600 hover:bg-gray-200"
                        }`}
                        onClick={() => handleTabClick(tab.id)}
                      >
                        <tab.icon
                          className={`stroke-current w-5 h-5  ${
                            activeTab === tab.label
                              ? "text-[#317A9A]"
                              : "text-[#88888]"
                          }`}
                        />
                        {tab.label}
                      </button>
                    ))}
                  </div>
                  {activeTab === "matching" && (
                    <>
                      <div className="flex md:flex-nowrap flex-wrap gap-5 mb-5">
                        <div className="max-w-[295px] w-full">
                          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
                            Branche wählen
                          </label>
                          <div className="relative">
                            <select
                              className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none"
                              value={selectedIndustry}
                              onChange={handleIndustryChange}
                            >
                              <option value="">Branche wählen</option>
                              {industries?.map((industry) => (
                                <option key={industry.id} value={industry.id}>
                                  {industry.name}
                                </option>
                              ))}
                            </select>
                            <img
                              src={DownArrow}
                              alt="Arrow"
                              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                              size={20}
                            />
                          </div>
                        </div>
                        <div className="max-w-[295px] w-full">
                          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
                            Arbeitsplatz wählen
                          </label>
                          <div className="relative">
                            <select
                              className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none"
                              value={selectedJob}
                              disabled={!selectedIndustry}
                              onChange={handleJobChange}
                            >
                              <option>Arbeitsplatz wählen</option>
                              {selectedJobs?.map(({ job }) => (
                                <option key={job.id} value={job.name}>
                                  {job?.name}
                                </option>
                              ))}
                            </select>
                            <img
                              src={DownArrow}
                              alt="Arrow"
                              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                              size={20}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="overflow-x-auto bg-white rounded-[15px]">
                        <table className="table-fixed w-full border-collapse">
                          <thead>
                            <tr className="text-left border-b border-gray-200">
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[170px]">
                                Firma
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[195px]">
                                ARBEITSPLATZ
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap 2xl:w-auto w-[420px]">
                                DEINE KOMPETENZEN
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[180px]">
                                Kompetenzlücken
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[130px]">
                                Ähnliche
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[120px]">
                                Seminare
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[120px]">
                                Konakt
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredVacancies?.map((item) => {
                              const graphData = {
                                series: [
                                  {
                                    name: "Filled",
                                    data:
                                      item?.competences?.map(
                                        (comp) => comp.value
                                      ) || [],
                                  },
                                  {
                                    name: "Remaining",
                                    data:
                                      item?.competences?.map(
                                        (comp) => 100 - comp.value
                                      ) || [],
                                  },
                                ],
                                competences:
                                  item?.competences?.map((comp) => ({
                                    name: comp.skill.name,
                                  })) || [],
                              };
                              return (
                                <tr
                                  className="border-b border-gray-200"
                                  key={item._id}
                                >
                                  <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                                    {item?.company?.name}
                                  </td>
                                  <td
                                    className="px-5 py-[15px] text-center align-top"
                                    onClick={() => handleBarClick(item?._id)}
                                  >
                                    <p className="text-sm leading-[17px] text-center mb-[10px]">
                                      Altenpflege-Leitung
                                    </p>
                                    <CustomApexBarChart
                                      // eslint-disable-next-line no-sequences
                                      colors={["#317A9A", "#F2F6FA"]}
                                      series={graphData.series}
                                      competences={graphData.competences}
                                    />
                                  </td>
                                  <td className="px-5 py-[15px] align-top">
                                    <div className="flex gap-3">
                                      <div
                                        className="px-2"
                                        onClick={() => handleClick(item?._id)}
                                      >
                                        <p className="text-sm leading-[17px] text-center mb-[10px]">
                                          Kompetenzprofil
                                        </p>
                                        <CustomApexBarChart
                                          colors={["#F0884D", "#F2F6FA"]}
                                          series={[
                                            {
                                              name: "Filled",
                                              data:
                                                selectedSkills?.map(
                                                  (skill) => skill?.percentage
                                                ) || [],
                                            },
                                            {
                                              name: "Remaining",
                                              data:
                                                selectedSkills?.map(
                                                  (skill) =>
                                                    100 - skill?.percentage
                                                ) || [],
                                            },
                                          ]}
                                          competences={
                                            selectedSkills?.map((skill) => ({
                                              name: skill?.name,
                                            })) || []
                                          }
                                        />
                                      </div>
                                      <div className="px-2">
                                        <p className="text-sm leading-[17px] text-center mb-[50px]">
                                          Humanpotential
                                        </p>
                                        <CircularProgressChart
                                          label={"Humanpotential"}
                                          percentage={Math.ceil(
                                            calculateHumanpotential
                                          )}
                                          height={60}
                                          width={60}
                                        />
                                      </div>
                                      <div className="px-2">
                                        <p className="text-sm leading-[17px] text-center mb-[50px]">
                                          Heatmap
                                        </p>
                                        <div
                                          className={`w-3 h-3 rounded-[3px] mx-auto ${
                                            true
                                              ? "bg-[#009951]"
                                              : "bg-[#BF5547]"
                                          }`}
                                        ></div>
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    className="px-5 py-[15px] align-top cursor-pointer"
                                    onClick={handleOpenDialog}
                                  >
                                    <div className="mt-[30px]">
                                      <CompetencyApexBarChart
                                        colors={["#083D4E", "#009951"]}
                                        series={[
                                          {
                                            name: "Arbeitsplatz",
                                            data:
                                              item?.competences?.map(
                                                (comp) => comp.value
                                              ) || [],
                                          },
                                          {
                                            name: "Mitarbeiter",
                                            data:
                                              item?.competences?.map((comp) => {
                                                const skillName =
                                                  comp.skill.name;
                                                return skillName in userSkills
                                                  ? userSkills[skillName]
                                                  : null;
                                              }) || [],
                                          },
                                        ]}
                                        categories={
                                          item?.competences?.map(
                                            (comp) => comp.skill.name
                                          ) || []
                                        }
                                      />
                                    </div>
                                  </td>
                                  {isDialogOpen && (
                                    <>
                                      <CompetencyDialog
                                        isOpen={isDialogOpen}
                                        setIsOpen={setIsDialogOpen}
                                        colors={["#083D4E", "#009951"]}
                                        series={[
                                          {
                                            name: "Arbeitsplatz",
                                            data:
                                              item?.competences?.map(
                                                (comp) => comp.value
                                              ) || [],
                                          },
                                          {
                                            name: "Mitarbeiter",
                                            data:
                                              item?.competences?.map((comp) => {
                                                const skillName =
                                                  comp.skill.name;
                                                // If skill exists in userSkills, use that value, otherwise return null or 0
                                                return skillName in userSkills
                                                  ? userSkills[skillName]
                                                  : null;
                                              }) || [],
                                          },
                                        ]}
                                        categories={
                                          item?.competences?.map(
                                            (comp) => comp.skill.name
                                          ) || []
                                        }
                                      />
                                    </>
                                  )}
                                  <td className="p-4 text-right">
                                    {(() => {
                                      const requiredCompetencies =
                                        item.competences.map((comp) =>
                                          comp.skill.name.toLowerCase()
                                        );
                                      const matchingUsers =
                                        jobsUserProfile.filter((profile) => {
                                          if (
                                            !profile.skills ||
                                            profile.skills.length === 0
                                          )
                                            return false;

                                          const matchingSkills =
                                            profile.skills.filter((skill) =>
                                              requiredCompetencies.includes(
                                                skill.skillName.toLowerCase()
                                              )
                                            ).length;
                                          const matchPercentage =
                                            (matchingSkills /
                                              requiredCompetencies.length) *
                                            100;
                                          return matchPercentage >= 50;
                                        });
                                      const getProfileImage = (userImage) => {
                                        if (userImage) {
                                          return (
                                            <img
                                              src={userImage}
                                              alt="userProfile"
                                              className="w-full h-full object-cover rounded-full"
                                            />
                                          );
                                        } else {
                                          return (
                                            <img
                                              src={userProfileImg}
                                              alt="userProfile"
                                              className="w-full h-full object-cover rounded-full"
                                            />
                                          );
                                        }
                                      };
                                      // const getInitials = (username) => {
                                      //   if (!username) {
                                      //     return (
                                      //       <img
                                      //         src={userProfileImg}
                                      //         alt="userProfile"
                                      //       />
                                      //     );
                                      //   } else {
                                      //     const names = username.split(" ");
                                      //     return names
                                      //       .map((name) =>
                                      //         name.charAt(0).toUpperCase()
                                      //       )
                                      //       .join("");
                                      //   }
                                      // };

                                      const sidebarData = {
                                        title: "Arbeitsplätze Details",
                                        skillName: item.skillName,
                                        content:
                                          matchingUsers.length > 0 ? (
                                            matchingUsers.map((user, index) => (
                                              <div
                                                key={index}
                                                className="flex items-center justify-between border-b py-4 cursor-pointer"
                                                onClick={() => {
                                                  navigate(
                                                    `/UserDetailPage/${user.userId}`,
                                                    {
                                                      state: {
                                                        userId: user.userId,
                                                        skills: user.skills,
                                                        userName: user.username,
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                <div className="flex items-center gap-2">
                                                  <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#DFE8F0] text-[#083D4E]">
                                                    {user.userImage ? (
                                                      <img
                                                        src={user.userImage}
                                                        alt="userProfile"
                                                        className="w-full h-full object-cover rounded-full"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={userProfileImg}
                                                        alt="userProfile"
                                                        className="w-full h-full object-cover rounded-full"
                                                      />
                                                    )}
                                                  </div>
                                                  <div>
                                                    <h3 className="font-medium">
                                                      {user.username ||
                                                        "Unknown User"}
                                                    </h3>
                                                  </div>
                                                </div>
                                                <button className="text-gray-500 hover:text-gray-700 cursor-pointer">
                                                  ⋮
                                                </button>
                                              </div>
                                            ))
                                          ) : (
                                            <p>
                                              No matching users found for this
                                              skill.
                                            </p>
                                          ),
                                      };

                                      return (
                                        <>
                                          <div className="flex mt-[50px]">
                                            {matchingUsers.length > 0 ? (
                                              <>
                                                {matchingUsers
                                                  .slice(0, 1)
                                                  .map((user, index) => (
                                                    <div
                                                      key={user.userId}
                                                      className={`w-[50px] h-[50px] rounded-full border-2 border-[#F2F6FA] flex items-center justify-center 
                  bg-[#317A9A] text-[#F2F6FA] ${
                    index !== 0 ? "ml-[-12px]" : ""
                  } cursor-pointer`}
                                                      onClick={() => {
                                                        navigate(
                                                          `/UserDetailPage/${user.userId}`,
                                                          {
                                                            state: {
                                                              userId:
                                                                user.userId,
                                                              skills:
                                                                user.skills,
                                                              userName:
                                                                user.username,
                                                            },
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      {/* {getInitials(
                                                        user.username
                                                      )} */}
                                                      {getProfileImage(
                                                        user.userImage
                                                      )}
                                                    </div>
                                                  ))}
                                                {matchingUsers.length > 1 && (
                                                  <div
                                                    className={`w-[50px] h-[50px] rounded-full border-2 border-[#F2F6FA] flex items-center justify-center 
                  bg-[#317A9A] text-[#F2F6FA] ml-[-12px] cursor-pointer`}
                                                    onClick={handleOpenSidebar}
                                                  >
                                                    +{matchingUsers.length - 1}
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <span className="text-gray-500">
                                                No matching users
                                              </span>
                                            )}
                                          </div>
                                          <Sidebar
                                            isOpen={isSidebarOpen}
                                            onClose={closeSidebar}
                                            title={sidebarData.title}
                                            skillName={sidebarData.skillName}
                                            content={sidebarData.content}
                                          />
                                        </>
                                      );
                                    })()}
                                  </td>

                                  <td className="px-5 py-2.5">
                                    <div className="bg-[#F2F6FA] text-sm text-[#333333] py-2.5 px-[3px] rounded-[5px] whitespace-nowrap mt-[50px]">
                                      1 gefunden
                                    </div>
                                  </td>
                                  <td className="px-5 py-2.5">
                                    <div className="bg-[#F2F6FA] text-sm text-[#333333] py-2.5 !px-2 rounded-[5px] whitespace-nowrap mt-[50px]">
                                      Nachricht
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {loading ? (
                          <Loader />
                        ) : (
                          <table className="table-fixed w-full border-collapse">
                            <thead></thead>
                          </table>
                        )}
                      </div>
                    </>
                  )}
                  {activeTab === "search" && <EmployeeCompetenciesDashboard />}
                  {activeTab === "competencymap" && (
                    <div className="w-full mx-auto bg-white h-[632px] overflow-hidden p-3 rounded-xl">
                      <WorldMap />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid lg:grid-cols-3 mt-10 mb-5 lg:gap-3 gap-[30px]">
                <div>
                  <h2 className="lg:text-2xl md:text-[20px] text-lg text-[#333333] font-bold mb-[15px]">
                    Dein perfekter Job
                  </h2>
                  <ul className="list-none">
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Matching & Vergleichen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzen finden
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzlücken erkennen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Seminare finden
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzmappe
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="lg:text-2xl md:text-[20px] text-lg text-[#333333] font-bold mb-[15px]">
                    Die perfekte Wissensorganisation
                  </h2>
                  <ul className="list-none">
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Matching & Vergleichen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzen finden
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Simulieren & Visualisieren
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        KPI's zum Performancemanagement
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="lg:text-2xl md:text-[20px] text-lg text-[#333333] font-bold mb-[15px]">
                    Das perfekte Team erstellen
                  </h2>
                  <ul className="list-none">
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Teamkompetenzen erstellen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Simulieren & Visualisieren
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;

import { X } from "lucide-react";
import React from "react";

const Sidebar = ({ isOpen, onClose, title, content, skillName }) => {
  if (!isOpen) return null; // Don't render if not open

  return (
    <div className="fixed top-0 right-0 h-screen w-full max-w-[500px] bg-white shadow-lg z-50 rounded-l-lg transition-all">
      <div className="py-7 px-6 flex justify-between items-center border-b">
        <div>
          <h2 className="text-2xl font-semibold">{title}</h2>
          <h3 className="text-base font-medium text-[#333333] pt-2">
            {skillName}
          </h3>
        </div>
        <X
          className="text-gray-500 hover:text-gray-700 cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="px-6 pt-8 max-h-[calc(100vh_-_121px)] overflow-auto">
        {content}
      </div>
    </div>
  );
};

export default Sidebar;

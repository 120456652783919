import { useState } from "react";
import PasswordShow from "../../assets/icon/Eye.svg";
import PasswordNotShow from "../../assets/icon/PasswordNotShow.svg";

const PasswordInput = ({
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  onBlur,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div className="relative">
      <label
        htmlFor={id}
        className="block text-[#333333] text-base font-medium mb-[10px]"
      >
        {label}
      </label>
      <div className="relative">
        <input
          type={isVisible ? "text" : "password"}
          id={id}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          className="w-full p-[10px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
          placeholder={placeholder}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 flex items-center px-2"
          onClick={toggleVisibility}
        >
          <img
            src={isVisible ? PasswordShow : PasswordNotShow}
            alt={isVisible ? "Hide password" : "Show password"}
            className="w-5 h-5"
          />
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;

import React, { useEffect, useState } from "react";
import DownArrow from "../../../../assets/images/downSideArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { fetchSkills } from "../../../../redux/slice/skillSlice";
import TwoPerson from "../../../../assets/images/twoPerson.svg";
import WorkBag from "../../../../assets/images/workBag.svg";
import Seminar from "../../../../assets/images/seminar.svg";
import Pagination from "../../../../components/Pagination";
import Sidebar from "../../../../components/Sidebar";
import { fetchJobsUserProfile } from "../../../../redux/slice/jobsUsersSlice";
import { fetchVacancies } from "../../../../redux/slice/vacanciesSlice";
import { useNavigate } from "react-router-dom";
import userProfileImg from "../../../../assets/icon/userProfile.png";

const CompetenciesGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { skills, loading } = useSelector((state) => state.skills);
  const { jobsUserProfile } = useSelector((state) => state.jobsUser);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarData, setSidebarData] = useState({
    title: "",
    skillName: "",
    content: null,
  });
  const { vacancies } = useSelector((state) => state.vacancies);

  useEffect(() => {
    dispatch(fetchSkills());
    dispatch(fetchJobsUserProfile());
    dispatch(fetchVacancies());
  }, [dispatch]);

  const totalPages = Math.ceil(skills.length / itemsPerPage);
  const paginatedSkills = skills.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openSidebar = (type, skillName) => {
    let data;

    switch (type) {
      case "Mitarbeiter":
        const filteredUsers = jobsUserProfile.filter((user) =>
          user.skills.some((skill) => skill.skillName === skillName)
        );
        data = {
          title: "Mitarbeiter mit Kompetenz",
          skillName,
          content:
            filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between border-b py-4"
                >
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      navigate(`/UserDetailPage/${user.userId}`, {
                        state: {
                          userId: user.userId,
                          skills: user.skills,
                          userName: user.username,
                          userImage:user.userImage
                        },
                      });
                    }}
                  >
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#DFE8F0] text-[#083D4E]">
                      {user.userImage ? (
                        <img
                          src={user.userImage}
                          alt="userProfile"
                          className="w-full h-full object-cover rounded-full"
                        />
                      ) : (
                        <img
                          src={userProfileImg}
                          alt="userProfile"
                          className="w-full h-full object-cover rounded-full"
                        />
                      )}
                    </div>
                    <div>
                      <h3 className="font-medium">
                        {user.username || "Unknown User"}
                      </h3>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                  </div>
                  <button className="text-gray-500 hover:text-gray-700 cursor-pointer">
                    ⋮
                  </button>
                </div>
              ))
            ) : (
              <p>No users found with this skill.</p>
            ),
        };
        break;
      case "Arbeitsplätze":
        const filteredVacancies = vacancies.filter((vacancy) =>
          vacancy.competences.some(
            (competence) => competence.skill.name === skillName
          )
        );
        data = {
          title: "Arbeitsplätze Details",
          skillName,
          content:
            filteredVacancies.length > 0 ? (
              filteredVacancies.map((vacancy, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between border-b py-4 cursor-pointer"
                  onClick={() => {
                    navigate(`/jobsDetail/${vacancy._id}`, {
                      state: {
                        vacancy: vacancy.job?.name,
                      },
                    });
                  }}
                >
                  <div className="flex items-center gap-2">
                    <div>
                      <h3 className="font-medium">{vacancy.job?.name}</h3>
                      <p className="text-sm text-gray-500">
                        {vacancy.description || "No description available"}
                      </p>
                    </div>
                  </div>
                  <button className="text-gray-500 hover:text-gray-700 cursor-pointer">
                    ⋮
                  </button>
                </div>
              ))
            ) : (
              <p>No vacancies found for this skill.</p>
            ),
        };
        break;
      case "Seminare":
        data = {
          title: "Seminare Details",
          skillName,
          content: Array(4)
            .fill({
              name: "Test seminare",
              desc: "test",
            })
            .map((data, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b py-4"
              >
                <div className="flex items-center gap-2">
                  <div>
                    <h3
                      className="font-medium cursor-pointer"
                      onClick={() => {
                        navigate(`/Seminar/:id`, {});
                      }}
                    >
                      {data.name}
                    </h3>
                    <p className="text-sm text-gray-500">{data.desc}</p>
                  </div>
                </div>
                <button className="text-gray-500 hover:text-gray-700">⋮</button>
              </div>
            )),
        };
        break;
      default:
        data = { title: "", skillName: "", content: null };
    }

    setSidebarData(data);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="max-w-full overflow-x-auto bg-[#fff] rounded-[15px]">
          <table className="w-[650px] sm:w-full border-collapse">
            <thead>
              <tr>
                <th className="text-left p-4 font-normal text-gray-500 w-3/5 md:w-3/4">
                  NAME
                </th>
                <th
                  className="text-left p-4 font-normal text-gray-500 w-2/5 md:w-1/4"
                  colSpan={2}
                >
                  VERWENDUNG
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedSkills.length < 1 ? (
                <tr>
                  <td colSpan={2} className="p-5 text-center font-semibold">
                    No Kompetenzen data is available.
                  </td>
                </tr>
              ) : (
                paginatedSkills.map((data, index) => (
                  <tr key={index} className="border-t border-gray-200">
                    <td className="py-8 px-4 align-top">
                      <div className="flex gap-3 items-start">
                        <img className="" src={DownArrow} alt="down-arrow" />
                        <div className="w-full">
                          <div className="text-base font-semibold text-[#333333] mb-2 leading-none">
                            {data?.name}
                          </div>
                          <div className="text-[14px] font-[500px] text-[#333333] text-wrap">
                            {data?.description}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-4 cursor-pointer ">
                      <div
                        className="flex gap-2 mb-3"
                        onClick={() => openSidebar("Mitarbeiter", data?.name)}
                      >
                        <p className="flex gap-2 w-32">
                          <img src={TwoPerson} alt="person-svg" />
                          <span>Mitarbeiter</span>
                        </p>
                        <span>
                          {
                            jobsUserProfile.filter((user) =>
                              user.skills.some(
                                (skill) => skill.skillName === data?.name
                              )
                            ).length
                          }
                        </span>
                      </div>
                      <div
                        className="flex gap-2 mb-3"
                        onClick={() => openSidebar("Arbeitsplätze", data?.name)}
                      >
                        <p className="flex gap-2 w-32">
                          <img src={WorkBag} alt="work-svg" />
                          <span>Arbeitsplätze</span>
                        </p>
                        <span>
                          {
                            vacancies.filter((vacancy) =>
                              vacancy.competences.some(
                                (competence) =>
                                  competence.skill.name === data?.name
                              )
                            ).length
                          }
                        </span>
                      </div>
                      <div
                        className="flex gap-2"
                        onClick={() => openSidebar("Seminare", data?.name)}
                      >
                        <p className="flex gap-2 w-32">
                          <img src={Seminar} alt="seminar-svg" />
                          <span>Seminare</span>
                        </p>
                        <span>0</span>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <Sidebar
            isOpen={isSidebarOpen}
            onClose={closeSidebar}
            title={sidebarData.title}
            skillName={sidebarData.skillName}
            content={sidebarData.content}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default CompetenciesGroup;
